export default {
  "AB10": "Scottish Water",
  "AB11": "Scottish Water",
  "AB12": "Scottish Water",
  "AB13": "Scottish Water",
  "AB14": "Scottish Water",
  "AB15": "Scottish Water",
  "AB16": "Scottish Water",
  "AB21": "Scottish Water",
  "AB22": "Scottish Water",
  "AB23": "Scottish Water",
  "AB24": "Scottish Water",
  "AB25": "Scottish Water",
  "AB30": "Scottish Water",
  "AB31": "Scottish Water",
  "AB32": "Scottish Water",
  "AB33": "Scottish Water",
  "AB34": "Scottish Water",
  "AB35": "Scottish Water",
  "AB36": "Scottish Water",
  "AB37": "Scottish Water",
  "AB38": "Scottish Water",
  "AB39": "Scottish Water",
  "AB41": "Scottish Water",
  "AB42": "Scottish Water",
  "AB43": "Scottish Water",
  "AB44": "Scottish Water",
  "AB45": "Scottish Water",
  "AB51": "Scottish Water",
  "AB52": "Scottish Water",
  "AB53": "Scottish Water",
  "AB54": "Scottish Water",
  "AB55": "Scottish Water",
  "AB56": "Scottish Water",
  "AB99": "Scottish Water",
  "AL1": "Affinity Water",
  "AL10": "Affinity Water",
  "AL2": "Affinity Water",
  "AL3": "Affinity Water",
  "AL4": "Affinity Water",
  "AL5": "Affinity Water",
  "AL6": "Affinity Water",
  "AL7": "Affinity Water",
  "AL8": "Affinity Water",
  "AL9": "Affinity Water",
  "B1": "Severn Trent",
  "B10": "Severn Trent",
  "B11": "Severn Trent",
  "B12": "Severn Trent",
  "B13": "Severn Trent",
  "B14": "Severn Trent",
  "B15": "Severn Trent",
  "B16": "Severn Trent",
  "B17": "Severn Trent",
  "B18": "Severn Trent",
  "B19": "Severn Trent",
  "B2": "Severn Trent",
  "B20": "Severn Trent",
  "B21": "Severn Trent",
  "B23": "Severn Trent",
  "B24": "Severn Trent",
  "B25": "Severn Trent",
  "B26": "Severn Trent",
  "B27": "Severn Trent",
  "B28": "Severn Trent",
  "B29": "Severn Trent",
  "B3": "Severn Trent",
  "B30": "Severn Trent",
  "B31": "Severn Trent",
  "B32": "Severn Trent",
  "B33": "Severn Trent",
  "B34": "Severn Trent",
  "B35": "South Staffordshire Water",
  "B36": "South Staffordshire Water",
  "B37": "Severn Trent",
  "B38": "Severn Trent",
  "B4": "Severn Trent",
  "B42": "Severn Trent",
  "B43": "South Staffordshire Water",
  "B44": "Severn Trent",
  "B45": "Severn Trent",
  "B46": "Severn Trent",
  "B47": "Severn Trent",
  "B48": "Severn Trent",
  "B49": "Severn Trent",
  "B5": "Severn Trent",
  "B50": "Severn Trent",
  "B6": "Severn Trent",
  "B60": "Severn Trent",
  "B61": "Severn Trent",
  "B62": "South Staffordshire Water",
  "B63": "South Staffordshire Water",
  "B64": "South Staffordshire Water",
  "B65": "South Staffordshire Water",
  "B66": "South Staffordshire Water",
  "B67": "South Staffordshire Water",
  "B68": "South Staffordshire Water",
  "B69": "Severn Trent",
  "B7": "Severn Trent",
  "B70": "South Staffordshire Water",
  "B71": "South Staffordshire Water",
  "B72": "South Staffordshire Water",
  "B73": "Severn Trent",
  "B74": "Severn Trent",
  "B75": "Severn Trent",
  "B76": "Severn Trent",
  "B77": "South Staffordshire Water",
  "B78": "Severn Trent",
  "B79": "Severn Trent",
  "B8": "Severn Trent",
  "B80": "Severn Trent",
  "B9": "Severn Trent",
  "B90": "Severn Trent",
  "B91": "Severn Trent",
  "B92": "Severn Trent",
  "B93": "Severn Trent",
  "B94": "Severn Trent",
  "B95": "Severn Trent",
  "B96": "Severn Trent",
  "B97": "Severn Trent",
  "B98": "Severn Trent",
  "BA1": "Wessex Water",
  "BA10": "Wessex Water",
  "BA11": "Bristol Water",
  "BA12": "Wessex Water",
  "BA13": "Wessex Water",
  "BA14": "Wessex Water",
  "BA15": "Wessex Water",
  "BA16": "Bristol Water",
  "BA2": "Bristol Water",
  "BA20": "Wessex Water",
  "BA21": "Wessex Water",
  "BA22": "Wessex Water",
  "BA3": "Bristol Water",
  "BA4": "Bristol Water",
  "BA5": "Bristol Water",
  "BA6": "Bristol Water",
  "BA7": "Wessex Water",
  "BA8": "Wessex Water",
  "BA9": "Wessex Water",
  "BB1": "United Utilities",
  "BB10": "United Utilities",
  "BB11": "United Utilities",
  "BB12": "United Utilities",
  "BB18": "Yorkshire Water",
  "BB2": "United Utilities",
  "BB3": "United Utilities",
  "BB4": "United Utilities",
  "BB5": "United Utilities",
  "BB6": "United Utilities",
  "BB7": "United Utilities",
  "BB8": "United Utilities",
  "BB9": "United Utilities",
  "BD1": "Yorkshire Water",
  "BD10": "Yorkshire Water",
  "BD11": "Yorkshire Water",
  "BD12": "Yorkshire Water",
  "BD13": "Yorkshire Water",
  "BD14": "Yorkshire Water",
  "BD15": "Yorkshire Water",
  "BD16": "Yorkshire Water",
  "BD17": "Yorkshire Water",
  "BD18": "Yorkshire Water",
  "BD19": "Yorkshire Water",
  "BD2": "Yorkshire Water",
  "BD20": "Yorkshire Water",
  "BD21": "Yorkshire Water",
  "BD22": "Yorkshire Water",
  "BD23": "Yorkshire Water",
  "BD24": "Yorkshire Water",
  "BD3": "Yorkshire Water",
  "BD4": "Yorkshire Water",
  "BD5": "Yorkshire Water",
  "BD6": "Yorkshire Water",
  "BD7": "Yorkshire Water",
  "BD8": "Yorkshire Water",
  "BD9": "Yorkshire Water",
  "BD97": "Yorkshire Water",
  "BD98": "Yorkshire Water",
  "BD99": "Yorkshire Water",
  "BH1": "Bournemouth Water",
  "BH10": "Bournemouth Water",
  "BH11": "Bournemouth Water",
  "BH12": "Wessex Water",
  "BH13": "Wessex Water",
  "BH14": "Wessex Water",
  "BH15": "Wessex Water",
  "BH16": "Wessex Water",
  "BH17": "Wessex Water",
  "BH18": "Wessex Water",
  "BH19": "Wessex Water",
  "BH2": "Bournemouth Water",
  "BH20": "Wessex Water",
  "BH21": "Bournemouth Water",
  "BH22": "Bournemouth Water",
  "CT15": "Affinity Water",
  "BH24": "Bournemouth Water",
  "BH25": "Bournemouth Water",
  "BH3": "Bournemouth Water",
  "BH31": "Bournemouth Water",
  "BH4": "Bournemouth Water",
  "BH5": "Bournemouth Water",
  "BH6": "Bournemouth Water",
  "BH7": "Bournemouth Water",
  "BH8": "Bournemouth Water",
  "BH9": "Bournemouth Water",
  "BL0": "United Utilities",
  "BL1": "United Utilities",
  "BL2": "United Utilities",
  "BL3": "United Utilities",
  "BL4": "United Utilities",
  "BL5": "United Utilities",
  "BL6": "United Utilities",
  "BL7": "United Utilities",
  "BL78": "United Utilities",
  "BL8": "United Utilities",
  "BL9": "United Utilities",
  "BN1": "Southern Water",
  "BN10": "South East Water",
  "BN11": "Southern Water",
  "BN12": "Southern Water",
  "BN13": "Southern Water",
  "BN14": "Southern Water",
  "BN15": "Southern Water",
  "BN16": "Southern Water",
  "BN17": "Southern Water",
  "BN18": "Southern Water",
  "BN2": "Southern Water",
  "BN20": "South East Water",
  "BN21": "South East Water",
  "BN22": "South East Water",
  "BN23": "South East Water",
  "BN24": "South East Water",
  "BN25": "South East Water",
  "BN26": "South East Water",
  "BN27": "South East Water",
  "BN3": "Southern Water",
  "BN41": "Southern Water",
  "BN42": "Southern Water",
  "BN43": "Southern Water",
  "BN44": "Southern Water",
  "BN45": "Southern Water",
  "BN5": "Southern Water",
  "BN6": "South East Water",
  "BN7": "Southern Water",
  "BN8": "South East Water",
  "BN9": "South East Water",
  "BR1": "Thames Water",
  "BR2": "Thames Water",
  "BR3": "Thames Water",
  "BR4": "Thames Water",
  "BR5": "Thames Water",
  "BR6": "Thames Water",
  "BR7": "Thames Water",
  "BR8": "Thames Water",
  "BS1": "Bristol Water",
  "BS10": "Bristol Water",
  "BS11": "Bristol Water",
  "BS13": "Bristol Water",
  "BS14": "Bristol Water",
  "BS15": "Bristol Water",
  "BS16": "Bristol Water",
  "BS2": "Bristol Water",
  "BS20": "Bristol Water",
  "BS21": "Bristol Water",
  "BS22": "Bristol Water",
  "BS23": "Bristol Water",
  "BS24": "Bristol Water",
  "BS25": "Bristol Water",
  "BS26": "Bristol Water",
  "BS27": "Bristol Water",
  "BS28": "Bristol Water",
  "BS29": "Bristol Water",
  "BS3": "Bristol Water",
  "BS30": "Bristol Water",
  "BS31": "Bristol Water",
  "BS32": "Bristol Water",
  "BS34": "Bristol Water",
  "BS35": "Bristol Water",
  "BS36": "Bristol Water",
  "BS37": "Bristol Water",
  "BS39": "Bristol Water",
  "BS4": "Bristol Water",
  "BS40": "Bristol Water",
  "BS41": "Bristol Water",
  "BS48": "Bristol Water",
  "BS49": "Bristol Water",
  "BS5": "Bristol Water",
  "BS6": "Bristol Water",
  "BS7": "Bristol Water",
  "BS8": "Bristol Water",
  "BS9": "Bristol Water",
  "BT1": "Northern Ireland Water",
  "BT10": "Northern Ireland Water",
  "BT11": "Northern Ireland Water",
  "BT12": "Northern Ireland Water",
  "BT13": "Northern Ireland Water",
  "BT14": "Northern Ireland Water",
  "BT15": "Northern Ireland Water",
  "BT16": "Northern Ireland Water",
  "BT17": "Northern Ireland Water",
  "BT18": "Northern Ireland Water",
  "BT19": "Northern Ireland Water",
  "BT2": "Northern Ireland Water",
  "BT20": "Northern Ireland Water",
  "BT21": "Northern Ireland Water",
  "BT22": "Northern Ireland Water",
  "BT23": "Northern Ireland Water",
  "BT24": "Northern Ireland Water",
  "BT25": "Northern Ireland Water",
  "BT26": "Northern Ireland Water",
  "BT27": "Northern Ireland Water",
  "BT28": "Northern Ireland Water",
  "BT29": "Northern Ireland Water",
  "BT3": "Northern Ireland Water",
  "BT30": "Northern Ireland Water",
  "BT31": "Northern Ireland Water",
  "BT32": "Northern Ireland Water",
  "BT33": "Northern Ireland Water",
  "BT34": "Northern Ireland Water",
  "BT35": "Northern Ireland Water",
  "BT36": "Northern Ireland Water",
  "BT37": "Northern Ireland Water",
  "BT38": "Northern Ireland Water",
  "BT39": "Northern Ireland Water",
  "BT4": "Northern Ireland Water",
  "BT40": "Northern Ireland Water",
  "BT41": "Northern Ireland Water",
  "BT42": "Northern Ireland Water",
  "BT43": "Northern Ireland Water",
  "BT44": "Northern Ireland Water",
  "BT45": "Northern Ireland Water",
  "BT46": "Northern Ireland Water",
  "BT47": "Northern Ireland Water",
  "BT48": "Northern Ireland Water",
  "BT49": "Northern Ireland Water",
  "BT5": "Northern Ireland Water",
  "BT51": "Northern Ireland Water",
  "BT52": "Northern Ireland Water",
  "BT53": "Northern Ireland Water",
  "BT54": "Northern Ireland Water",
  "BT55": "Northern Ireland Water",
  "BT56": "Northern Ireland Water",
  "BT57": "Northern Ireland Water",
  "BT58": "Northern Ireland Water",
  "BT6": "Northern Ireland Water",
  "BT60": "Northern Ireland Water",
  "BT61": "Northern Ireland Water",
  "BT62": "Northern Ireland Water",
  "BT63": "Northern Ireland Water",
  "BT64": "Northern Ireland Water",
  "BT65": "Northern Ireland Water",
  "BT66": "Northern Ireland Water",
  "BT67": "Northern Ireland Water",
  "BT68": "Northern Ireland Water",
  "BT69": "Northern Ireland Water",
  "BT7": "Northern Ireland Water",
  "BT70": "Northern Ireland Water",
  "BT71": "Northern Ireland Water",
  "BT74": "Northern Ireland Water",
  "BT75": "Northern Ireland Water",
  "BT76": "Northern Ireland Water",
  "BT77": "Northern Ireland Water",
  "BT78": "Northern Ireland Water",
  "BT79": "Northern Ireland Water",
  "BT8": "Northern Ireland Water",
  "BT80": "Northern Ireland Water",
  "BT81": "Northern Ireland Water",
  "BT82": "Northern Ireland Water",
  "BT9": "Northern Ireland Water",
  "BT92": "Northern Ireland Water",
  "BT93": "Northern Ireland Water",
  "BT94": "Northern Ireland Water",
  "CA1": "United Utilities",
  "CA10": "United Utilities",
  "CA11": "United Utilities",
  "CA12": "United Utilities",
  "CA13": "United Utilities",
  "CA14": "United Utilities",
  "CA15": "United Utilities",
  "CA16": "United Utilities",
  "CA17": "United Utilities",
  "CA18": "United Utilities",
  "CA19": "United Utilities",
  "CA2": "United Utilities",
  "CA20": "United Utilities",
  "CA21": "United Utilities",
  "CA22": "United Utilities",
  "CA23": "United Utilities",
  "CA24": "United Utilities",
  "CA25": "United Utilities",
  "CA26": "United Utilities",
  "CA27": "United Utilities",
  "CA28": "United Utilities",
  "CA3": "United Utilities",
  "CA4": "United Utilities",
  "CA5": "United Utilities",
  "CA6": "United Utilities",
  "CA7": "United Utilities",
  "CA8": "Northumbrian Water",
  "CA9": "United Utilities",
  "CA95": "United Utilities",
  "CA99": "United Utilities",
  "CB1": "Cambridge Water",
  "CB10": "Affinity Water",
  "CB11": "Affinity Water",
  "CB2": "Cambridge Water",
  "CB21": "Cambridge Water",
  "CB22": "Cambridge Water",
  "CB23": "Cambridge Water",
  "CB24": "Cambridge Water",
  "CB25": "Cambridge Water",
  "CB3": "Cambridge Water",
  "CB4": "Cambridge Water",
  "CB5": "Cambridge Water",
  "CB6": "Anglian Water",
  "CB7": "Anglian Water",
  "CB8": "Anglian Water",
  "CB9": "Anglian Water",
  "CF10": "Dwr Cymru Welsh Water",
  "CF11": "Dwr Cymru Welsh Water",
  "CF14": "Dwr Cymru Welsh Water",
  "CF15": "Dwr Cymru Welsh Water",
  "CF23": "Dwr Cymru Welsh Water",
  "CF24": "Dwr Cymru Welsh Water",
  "CF3": "Dwr Cymru Welsh Water",
  "CF30": "Dwr Cymru Welsh Water",
  "CF31": "Dwr Cymru Welsh Water",
  "CF32": "Dwr Cymru Welsh Water",
  "CF33": "Dwr Cymru Welsh Water",
  "CF34": "Dwr Cymru Welsh Water",
  "CF35": "Dwr Cymru Welsh Water",
  "CF36": "Dwr Cymru Welsh Water",
  "CF37": "Dwr Cymru Welsh Water",
  "CF38": "Dwr Cymru Welsh Water",
  "CF39": "Dwr Cymru Welsh Water",
  "CF40": "Dwr Cymru Welsh Water",
  "CF41": "Dwr Cymru Welsh Water",
  "CF42": "Dwr Cymru Welsh Water",
  "CF43": "Dwr Cymru Welsh Water",
  "CF44": "Dwr Cymru Welsh Water",
  "CF45": "Dwr Cymru Welsh Water",
  "CF46": "Dwr Cymru Welsh Water",
  "CF47": "Dwr Cymru Welsh Water",
  "CF48": "Dwr Cymru Welsh Water",
  "CF5": "Dwr Cymru Welsh Water",
  "CF61": "Dwr Cymru Welsh Water",
  "CF62": "Dwr Cymru Welsh Water",
  "CF63": "Dwr Cymru Welsh Water",
  "CF64": "Dwr Cymru Welsh Water",
  "CF71": "Dwr Cymru Welsh Water",
  "CF72": "Dwr Cymru Welsh Water",
  "CF81": "Dwr Cymru Welsh Water",
  "CF82": "Dwr Cymru Welsh Water",
  "CF83": "Dwr Cymru Welsh Water",
  "CF95": "Dwr Cymru Welsh Water",
  "CF99": "Dwr Cymru Welsh Water",
  "CH1": "Severn Trent",
  "CH2": "Severn Trent",
  "CH3": "Severn Trent",
  "CH4": "Dee Valley",
  "CH41": "United Utilities",
  "CH42": "United Utilities",
  "CH43": "United Utilities",
  "CH44": "United Utilities",
  "CH45": "United Utilities",
  "CH46": "United Utilities",
  "CH47": "United Utilities",
  "CH48": "United Utilities",
  "CH49": "United Utilities",
  "CH5": "Dwr Cymru Welsh Water",
  "CH6": "Dwr Cymru Welsh Water",
  "CH60": "Dwr Cymru Welsh Water",
  "CH61": "United Utilities",
  "CH62": "United Utilities",
  "CH63": "United Utilities",
  "CH64": "United Utilities",
  "CH65": "United Utilities",
  "CH66": "United Utilities",
  "CH7": "Dwr Cymru Welsh Water",
  "CH8": "Dwr Cymru Welsh Water",
  "CH88": "Dwr Cymru Welsh Water",
  "CM0": "Essex & Suffolk Water",
  "CM1": "Anglian Water",
  "CM11": "Essex & Suffolk Water",
  "CM12": "Essex & Suffolk Water",
  "CM13": "Essex & Suffolk Water",
  "CM14": "Essex & Suffolk Water",
  "CM15": "Essex & Suffolk Water",
  "CM16": "Affinity Water",
  "CM17": "Affinity Water",
  "CM18": "Affinity Water",
  "CM19": "Affinity Water",
  "CM2": "Essex & Suffolk Water",
  "CM20": "Affinity Water",
  "CM21": "Affinity Water",
  "CM22": "Affinity Water",
  "CM23": "Affinity Water",
  "CM24": "Affinity Water",
  "CM3": "Affinity Water",
  "CM4": "Essex & Suffolk Water",
  "CM5": "Affinity Water",
  "CM6": "Affinity Water",
  "CM7": "Anglian Water",
  "CM77": "Anglian Water",
  "CM8": "Essex & Suffolk Water",
  "CM9": "Anglian Water",
  "CO1": "Anglian Water",
  "CO10": "Anglian Water",
  "CO11": "Affinity Water",
  "CO12": "Affinity Water",
  "CO13": "Affinity Water",
  "CO14": "Affinity Water",
  "CO15": "Affinity Water",
  "CO16": "Affinity Water",
  "CO2": "Anglian Water",
  "CO3": "Anglian Water",
  "CO4": "Anglian Water",
  "CO5": "Anglian Water",
  "CO6": "Anglian Water",
  "CO7": "Affinity Water",
  "CO8": "Anglian Water",
  "CO9": "Anglian Water",
  "CR0": "Thames Water",
  "CR2": "SES Water",
  "CR3": "SES Water",
  "CR4": "Thames Water",
  "CR44": "SES Water",
  "CR5": "SES Water",
  "CR6": "SES Water",
  "CR7": "Thames Water",
  "CR8": "SES Water",
  "CR9": "Thames Water",
  "CR90": "SES Water",
  "CT16": "Affinity Water",
  "CT18": "Affinity Water",
  "CT19": "Affinity Water",
  "CT12": "Southern Water",
  "CT21": "Affinity Water",
  "CT4": "Affinity Water",
  "EN5": "Affinity Water",
  "EN6": "Affinity Water",
  "CT17": "Affinity Water",
  "GU18": "Affinity Water",
  "GU19": "Affinity Water",
  "GU20": "Affinity Water",
  "CT20": "Affinity Water",
  "GU21": "Affinity Water",
  "CT3": "Southern Water",
  "GU22": "Affinity Water",
  "GU23": "Affinity Water",
  "GU24": "Affinity Water",
  "GU25": "Affinity Water",
  "GU35": "Affinity Water",
  "CT8": "Southern Water",
  "GU4": "Affinity Water",
  "HA0": "Affinity Water",
  "HA1": "Affinity Water",
  "HA2": "Affinity Water",
  "HA3": "Affinity Water",
  "HA4": "Affinity Water",
  "HA5": "Affinity Water",
  "HA6": "Affinity Water",
  "HA7": "Affinity Water",
  "HA8": "Affinity Water",
  "HA9": "Affinity Water",
  "HP1": "Affinity Water",
  "HP15": "Affinity Water",
  "HP16": "Affinity Water",
  "HP2": "Affinity Water",
  "HP3": "Affinity Water",
  "HP4": "Affinity Water",
  "HP5": "Affinity Water",
  "HP6": "Affinity Water",
  "HP7": "Affinity Water",
  "HP8": "Affinity Water",
  "HP9": "Affinity Water",
  "KT12": "Affinity Water",
  "KT13": "Affinity Water",
  "KT15": "Affinity Water",
  "CW1": "United Utilities",
  "KT16": "Affinity Water",
  "LU1": "Affinity Water",
  "LU2": "Affinity Water",
  "LU3": "Affinity Water",
  "LU4": "Affinity Water",
  "LU5": "Affinity Water",
  "LU6": "Affinity Water",
  "CW6": "United Utilities",
  "CW7": "United Utilities",
  "CW8": "United Utilities",
  "CW9": "United Utilities",
  "N11": "Affinity Water",
  "DA1": "Thames Water",
  "DA10": "Thames Water",
  "DA11": "Thames Water",
  "N12": "Affinity Water",
  "N2": "Affinity Water",
  "N20": "Affinity Water",
  "N3": "Affinity Water",
  "NW7": "Affinity Water",
  "NW9": "Affinity Water",
  "RM4": "Affinity Water",
  "SG1": "Affinity Water",
  "SG10": "Affinity Water",
  "SG11": "Affinity Water",
  "SG12": "Affinity Water",
  "SG14": "Affinity Water",
  "SG2": "Affinity Water",
  "SG3": "Affinity Water",
  "SG4": "Affinity Water",
  "DD1": "Scottish Water",
  "DD10": "Scottish Water",
  "DD11": "Scottish Water",
  "DD2": "Scottish Water",
  "DD3": "Scottish Water",
  "DD4": "Scottish Water",
  "DD5": "Scottish Water",
  "DD6": "Scottish Water",
  "DD7": "Scottish Water",
  "DD8": "Scottish Water",
  "DD9": "Scottish Water",
  "DE1": "Severn Trent",
  "SG5": "Affinity Water",
  "SG6": "Affinity Water",
  "SG7": "Affinity Water",
  "DE14": "Severn Trent",
  "DE15": "South Staffordshire Water",
  "SG8": "Affinity Water",
  "SG9": "Affinity Water",
  "SL0": "Affinity Water",
  "SL5": "Affinity Water",
  "SL9": "Affinity Water",
  "TN28": "Affinity Water",
  "TN29": "Affinity Water",
  "TW14": "Affinity Water",
  "TW15": "Affinity Water",
  "TW18": "Affinity Water",
  "TW20": "Affinity Water",
  "TW6": "Affinity Water",
  "UB1": "Affinity Water",
  "UB10": "Affinity Water",
  "UB11": "Affinity Water",
  "UB18": "Affinity Water",
  "UB2": "Affinity Water",
  "UB3": "Affinity Water",
  "DG1": "Scottish Water",
  "UB4": "Affinity Water",
  "UB5": "Affinity Water",
  "UB6": "Affinity Water",
  "UB8": "Affinity Water",
  "UB9": "Affinity Water",
  "WD17": "Affinity Water",
  "WD18": "Affinity Water",
  "WD19": "Affinity Water",
  "WD23": "Affinity Water",
  "WD24": "Affinity Water",
  "WD25": "Affinity Water",
  "WD3": "Affinity Water",
  "WD4": "Affinity Water",
  "WD5": "Affinity Water",
  "WD6": "Affinity Water",
  "WD7": "Affinity Water",
  "WD99": "Affinity Water",
  "IP1": "Anglian Water",
  "IP10": "Anglian Water",
  "IP11": "Anglian Water",
  "IP12": "Anglian Water",
  "IP13": "Anglian Water",
  "IP14": "Anglian Water",
  "IP2": "Anglian Water",
  "IP20": "Anglian Water",
  "IP21": "Anglian Water",
  "IP22": "Anglian Water",
  "IP24": "Anglian Water",
  "IP25": "Anglian Water",
  "IP26": "Anglian Water",
  "IP27": "Anglian Water",
  "IP28": "Anglian Water",
  "IP29": "Anglian Water",
  "IP3": "Anglian Water",
  "IP30": "Anglian Water",
  "IP31": "Anglian Water",
  "IP32": "Anglian Water",
  "IP33": "Anglian Water",
  "IP4": "Anglian Water",
  "DL6": "Yorkshire Water",
  "DL7": "Yorkshire Water",
  "DL8": "Yorkshire Water",
  "DL9": "Yorkshire Water",
  "DN1": "Yorkshire Water",
  "IP5": "Anglian Water",
  "IP6": "Anglian Water",
  "IP7": "Anglian Water",
  "IP8": "Anglian Water",
  "DN15": "Anglian Water",
  "DN16": "Anglian Water",
  "DN17": "Anglian Water",
  "DN18": "Anglian Water",
  "DN19": "Anglian Water",
  "IP9": "Anglian Water",
  "DN20": "Anglian Water",
  "DN21": "Anglian Water",
  "DN22": "Anglian Water",
  "DN3": "Yorkshire Water",
  "DN31": "Anglian Water",
  "DN32": "Anglian Water",
  "DN33": "Anglian Water",
  "DN34": "Anglian Water",
  "DN35": "Anglian Water",
  "DN36": "Anglian Water",
  "DN37": "Anglian Water",
  "DN38": "Anglian Water",
  "DN39": "Anglian Water",
  "DN4": "Yorkshire Water",
  "DN40": "Anglian Water",
  "DN41": "Anglian Water",
  "LN10": "Anglian Water",
  "LN11": "Anglian Water",
  "LN12": "Anglian Water",
  "LN13": "Anglian Water",
  "LN2": "Anglian Water",
  "LN3": "Anglian Water",
  "LN4": "Anglian Water",
  "LN5": "Anglian Water",
  "LN6": "Anglian Water",
  "LN7": "Anglian Water",
  "LN8": "Anglian Water",
  "LN9": "Anglian Water",
  "LU7": "Anglian Water",
  "MK1": "Anglian Water",
  "DT7": "South West Water",
  "MK10": "Anglian Water",
  "MK11": "Anglian Water",
  "DY1": "South Staffordshire Water",
  "MK12": "Anglian Water",
  "MK13": "Anglian Water",
  "MK15": "Anglian Water",
  "MK16": "Anglian Water",
  "MK17": "Anglian Water",
  "MK18": "Anglian Water",
  "MK19": "Anglian Water",
  "DY4": "Severn Trent",
  "MK2": "Anglian Water",
  "MK3": "Anglian Water",
  "MK4": "Anglian Water",
  "MK40": "Anglian Water",
  "DY9": "Severn Trent",
  "MK41": "Anglian Water",
  "MK42": "Anglian Water",
  "MK43": "Anglian Water",
  "MK44": "Anglian Water",
  "MK45": "Anglian Water",
  "MK46": "Anglian Water",
  "MK5": "Anglian Water",
  "MK6": "Anglian Water",
  "MK7": "Anglian Water",
  "MK77": "Anglian Water",
  "MK8": "Anglian Water",
  "MK9": "Anglian Water",
  "NG31": "Anglian Water",
  "NG32": "Anglian Water",
  "NG33": "Anglian Water",
  "NG34": "Anglian Water",
  "NN1": "Anglian Water",
  "NN10": "Anglian Water",
  "NN11": "Anglian Water",
  "NN12": "Anglian Water",
  "NN13": "Anglian Water",
  "NN14": "Anglian Water",
  "NN15": "Anglian Water",
  "NN16": "Anglian Water",
  "NN17": "Anglian Water",
  "NN2": "Anglian Water",
  "NN29": "Anglian Water",
  "NN3": "Anglian Water",
  "NN4": "Anglian Water",
  "NN5": "Anglian Water",
  "NN6": "Anglian Water",
  "NN7": "Anglian Water",
  "NN8": "Anglian Water",
  "NN9": "Anglian Water",
  "NR1": "Anglian Water",
  "NR10": "Anglian Water",
  "NR11": "Anglian Water",
  "NR12": "Anglian Water",
  "NR13": "Anglian Water",
  "NR14": "Anglian Water",
  "NR15": "Anglian Water",
  "NR16": "Anglian Water",
  "NR17": "Anglian Water",
  "NR18": "Anglian Water",
  "NR19": "Anglian Water",
  "NR2": "Anglian Water",
  "NR20": "Anglian Water",
  "NR21": "Anglian Water",
  "NR22": "Anglian Water",
  "NR23": "Anglian Water",
  "NR24": "Anglian Water",
  "NR25": "Anglian Water",
  "NR26": "Anglian Water",
  "NR27": "Anglian Water",
  "NR28": "Anglian Water",
  "NR29": "Anglian Water",
  "NR3": "Anglian Water",
  "NR33": "Anglian Water",
  "NR35": "Anglian Water",
  "NR4": "Anglian Water",
  "NR5": "Anglian Water",
  "NR6": "Anglian Water",
  "NR7": "Anglian Water",
  "NR8": "Anglian Water",
  "NR9": "Anglian Water",
  "NR99": "Anglian Water",
  "OX17": "Anglian Water",
  "PE1": "Anglian Water",
  "PE10": "Anglian Water",
  "PE11": "Anglian Water",
  "PE12": "Anglian Water",
  "PE13": "Anglian Water",
  "PE14": "Anglian Water",
  "PE15": "Anglian Water",
  "PE16": "Anglian Water",
  "PE19": "Anglian Water",
  "PE2": "Anglian Water",
  "PE20": "Anglian Water",
  "PE21": "Anglian Water",
  "PE22": "Anglian Water",
  "PE23": "Anglian Water",
  "PE24": "Anglian Water",
  "PE25": "Anglian Water",
  "PE28": "Anglian Water",
  "PE29": "Anglian Water",
  "PE3": "Anglian Water",
  "PE30": "Anglian Water",
  "PE31": "Anglian Water",
  "PE32": "Anglian Water",
  "PE33": "Anglian Water",
  "PE34": "Anglian Water",
  "PE35": "Anglian Water",
  "PE36": "Anglian Water",
  "PE37": "Anglian Water",
  "PE38": "Anglian Water",
  "PE4": "Anglian Water",
  "PE5": "Anglian Water",
  "PE6": "Anglian Water",
  "PE7": "Anglian Water",
  "PE8": "Anglian Water",
  "PE9": "Anglian Water",
  "SG15": "Anglian Water",
  "SG16": "Anglian Water",
  "SG17": "Anglian Water",
  "SG18": "Anglian Water",
  "SG19": "Anglian Water",
  "BH23": "Bournemouth Water",
  "SO41": "Bournemouth Water",
  "SP6": "Bournemouth Water",
  "GL9": "Bristol Water",
  "TA8": "Bristol Water",
  "EN4": "Thames Water",
  "EN7": "Thames Water",
  "EN8": "Thames Water",
  "EN9": "Thames Water",
  "EX1": "South West Water",
  "EX10": "South West Water",
  "EX11": "South West Water",
  "EX12": "South West Water",
  "EX13": "South West Water",
  "EX14": "South West Water",
  "EX15": "South West Water",
  "EX16": "South West Water",
  "EX17": "South West Water",
  "EX18": "South West Water",
  "EX19": "South West Water",
  "EX2": "South West Water",
  "EX20": "South West Water",
  "EX21": "South West Water",
  "EX22": "South West Water",
  "EX23": "South West Water",
  "EX24": "South West Water",
  "EX3": "South West Water",
  "EX31": "South West Water",
  "EX32": "South West Water",
  "EX33": "South West Water",
  "EX34": "South West Water",
  "EX35": "South West Water",
  "EX36": "South West Water",
  "EX37": "South West Water",
  "EX38": "South West Water",
  "EX39": "South West Water",
  "EX4": "South West Water",
  "EX5": "South West Water",
  "EX6": "South West Water",
  "EX7": "South West Water",
  "EX8": "South West Water",
  "EX9": "South West Water",
  "FK1": "Scottish Water",
  "FK10": "Scottish Water",
  "FK11": "Scottish Water",
  "FK12": "Scottish Water",
  "FK13": "Scottish Water",
  "FK14": "Scottish Water",
  "FK15": "Scottish Water",
  "FK16": "Scottish Water",
  "FK17": "Scottish Water",
  "FK18": "Scottish Water",
  "FK19": "Scottish Water",
  "FK2": "Scottish Water",
  "FK20": "Scottish Water",
  "FK21": "Scottish Water",
  "FK3": "Scottish Water",
  "FK4": "Scottish Water",
  "FK5": "Scottish Water",
  "FK6": "Scottish Water",
  "FK7": "Scottish Water",
  "FK8": "Scottish Water",
  "FK9": "Scottish Water",
  "G1": "Scottish Water",
  "G11": "Scottish Water",
  "G12": "Scottish Water",
  "G13": "Scottish Water",
  "G14": "Scottish Water",
  "G15": "Scottish Water",
  "G2": "Scottish Water",
  "G20": "Scottish Water",
  "G21": "Scottish Water",
  "G22": "Scottish Water",
  "G23": "Scottish Water",
  "G3": "Scottish Water",
  "G31": "Scottish Water",
  "G32": "Scottish Water",
  "G33": "Scottish Water",
  "G34": "Scottish Water",
  "G4": "Scottish Water",
  "G40": "Scottish Water",
  "G41": "Scottish Water",
  "G42": "Scottish Water",
  "G43": "Scottish Water",
  "G44": "Scottish Water",
  "G45": "Scottish Water",
  "G46": "Scottish Water",
  "G5": "Scottish Water",
  "G51": "Scottish Water",
  "G52": "Scottish Water",
  "G53": "Scottish Water",
  "G58": "Scottish Water",
  "G60": "Scottish Water",
  "G61": "Scottish Water",
  "G62": "Scottish Water",
  "G63": "Scottish Water",
  "G64": "Scottish Water",
  "G65": "Scottish Water",
  "G66": "Scottish Water",
  "G67": "Scottish Water",
  "G68": "Scottish Water",
  "G69": "Scottish Water",
  "G70": "Scottish Water",
  "G71": "Scottish Water",
  "G72": "Scottish Water",
  "G73": "Scottish Water",
  "G74": "Scottish Water",
  "G75": "Scottish Water",
  "G76": "Scottish Water",
  "G77": "Scottish Water",
  "G78": "Scottish Water",
  "G79": "Scottish Water",
  "G81": "Scottish Water",
  "G82": "Scottish Water",
  "G83": "Scottish Water",
  "G84": "Scottish Water",
  "G9": "Scottish Water",
  "G90": "Scottish Water",
  "GL1": "Severn Trent",
  "GL10": "Severn Trent",
  "GL11": "Severn Trent",
  "GL12": "Bristol Water",
  "GL13": "Severn Trent",
  "GL14": "Severn Trent",
  "GL15": "Severn Trent",
  "GL16": "Severn Trent",
  "GL17": "Severn Trent",
  "GL18": "Severn Trent",
  "GL19": "Severn Trent",
  "GL2": "Severn Trent",
  "GL20": "Severn Trent",
  "GL3": "Severn Trent",
  "GL4": "Severn Trent",
  "GL5": "Severn Trent",
  "GL50": "Severn Trent",
  "GL51": "Severn Trent",
  "GL52": "Severn Trent",
  "GL53": "Severn Trent",
  "GL6": "Severn Trent",
  "LL11": "Dee Valley Water",
  "LL12": "Dee Valley Water",
  "LL13": "Dee Valley Water",
  "LL14": "Dee Valley Water",
  "LL20": "Dee Valley Water",
  "SY15": "Dee Valley Water",
  "SY16": "Dee Valley Water",
  "SY17": "Dee Valley Water",
  "SY18": "Dee Valley Water",
  "SY19": "Dee Valley Water",
  "SY21": "Dee Valley Water",
  "SY22": "Dee Valley Water",
  "HR1": "Dwr Cymru Welsh Water",
  "HR2": "Dwr Cymru Welsh Water",
  "HR3": "Dwr Cymru Welsh Water",
  "HR4": "Dwr Cymru Welsh Water",
  "HR5": "Dwr Cymru Welsh Water",
  "HR6": "Dwr Cymru Welsh Water",
  "GU51": "South East Water",
  "GU52": "South East Water",
  "GU6": "Thames Water",
  "GU7": "Thames Water",
  "GU8": "Thames Water",
  "GU9": "South East Water",
  "HR7": "Dwr Cymru Welsh Water",
  "GY1": "Guernsey Water",
  "GY2": "Guernsey Water",
  "GY3": "Guernsey Water",
  "GY4": "Guernsey Water",
  "GY5": "Guernsey Water",
  "GY6": "Guernsey Water",
  "GY7": "Guernsey Water",
  "GY8": "Guernsey Water",
  "GY9": "Guernsey Water",
  "HR8": "Dwr Cymru Welsh Water",
  "HR9": "Dwr Cymru Welsh Water",
  "LL15": "Dwr Cymru Welsh Water",
  "LL16": "Dwr Cymru Welsh Water",
  "LL17": "Dwr Cymru Welsh Water",
  "LL18": "Dwr Cymru Welsh Water",
  "LL19": "Dwr Cymru Welsh Water",
  "LL21": "Dwr Cymru Welsh Water",
  "LL22": "Dwr Cymru Welsh Water",
  "LL23": "Dwr Cymru Welsh Water",
  "LL25": "Dwr Cymru Welsh Water",
  "LL26": "Dwr Cymru Welsh Water",
  "LL27": "Dwr Cymru Welsh Water",
  "LL28": "Dwr Cymru Welsh Water",
  "LL29": "Dwr Cymru Welsh Water",
  "LL30": "Dwr Cymru Welsh Water",
  "LL31": "Dwr Cymru Welsh Water",
  "LL32": "Dwr Cymru Welsh Water",
  "LL33": "Dwr Cymru Welsh Water",
  "LL34": "Dwr Cymru Welsh Water",
  "LL35": "Dwr Cymru Welsh Water",
  "LL36": "Dwr Cymru Welsh Water",
  "LL37": "Dwr Cymru Welsh Water",
  "LL38": "Dwr Cymru Welsh Water",
  "LL39": "Dwr Cymru Welsh Water",
  "HP10": "Thames Water",
  "HP11": "Thames Water",
  "HP12": "Thames Water",
  "HP13": "Thames Water",
  "HP14": "Thames Water",
  "LL41": "Dwr Cymru Welsh Water",
  "LL42": "Dwr Cymru Welsh Water",
  "HP17": "Thames Water",
  "HP18": "Thames Water",
  "HP19": "Thames Water",
  "LL43": "Dwr Cymru Welsh Water",
  "HP20": "Thames Water",
  "HP21": "Thames Water",
  "HP22": "Thames Water",
  "HP23": "Thames Water",
  "HP27": "Thames Water",
  "LL44": "Dwr Cymru Welsh Water",
  "LL45": "Dwr Cymru Welsh Water",
  "LL46": "Dwr Cymru Welsh Water",
  "LL47": "Dwr Cymru Welsh Water",
  "LL48": "Dwr Cymru Welsh Water",
  "LL49": "Dwr Cymru Welsh Water",
  "LL51": "Dwr Cymru Welsh Water",
  "LL52": "Dwr Cymru Welsh Water",
  "LL53": "Dwr Cymru Welsh Water",
  "LL54": "Dwr Cymru Welsh Water",
  "LL55": "Dwr Cymru Welsh Water",
  "LL56": "Dwr Cymru Welsh Water",
  "LL57": "Dwr Cymru Welsh Water",
  "LL58": "Dwr Cymru Welsh Water",
  "LL59": "Dwr Cymru Welsh Water",
  "LL61": "Dwr Cymru Welsh Water",
  "LL62": "Dwr Cymru Welsh Water",
  "LL63": "Dwr Cymru Welsh Water",
  "LL64": "Dwr Cymru Welsh Water",
  "LL65": "Dwr Cymru Welsh Water",
  "LL66": "Dwr Cymru Welsh Water",
  "LL67": "Dwr Cymru Welsh Water",
  "LL68": "Dwr Cymru Welsh Water",
  "LL69": "Dwr Cymru Welsh Water",
  "LL70": "Dwr Cymru Welsh Water",
  "LL71": "Dwr Cymru Welsh Water",
  "LL72": "Dwr Cymru Welsh Water",
  "LL73": "Dwr Cymru Welsh Water",
  "LL74": "Dwr Cymru Welsh Water",
  "LL75": "Dwr Cymru Welsh Water",
  "LL76": "Dwr Cymru Welsh Water",
  "LL77": "Dwr Cymru Welsh Water",
  "LL78": "Dwr Cymru Welsh Water",
  "SA1": "Dwr Cymru Welsh Water",
  "SA10": "Dwr Cymru Welsh Water",
  "SA11": "Dwr Cymru Welsh Water",
  "SA12": "Dwr Cymru Welsh Water",
  "SA13": "Dwr Cymru Welsh Water",
  "SA14": "Dwr Cymru Welsh Water",
  "SA15": "Dwr Cymru Welsh Water",
  "SA16": "Dwr Cymru Welsh Water",
  "SA17": "Dwr Cymru Welsh Water",
  "SA18": "Dwr Cymru Welsh Water",
  "SA19": "Dwr Cymru Welsh Water",
  "SA2": "Dwr Cymru Welsh Water",
  "SA20": "Dwr Cymru Welsh Water",
  "SA3": "Dwr Cymru Welsh Water",
  "SA31": "Dwr Cymru Welsh Water",
  "SA32": "Dwr Cymru Welsh Water",
  "SA33": "Dwr Cymru Welsh Water",
  "SA34": "Dwr Cymru Welsh Water",
  "SA35": "Dwr Cymru Welsh Water",
  "IG1": "Essex & Suffolk Water",
  "IG10": "Thames Water",
  "IG11": "Essex & Suffolk Water",
  "IG2": "Thames Water",
  "IG3": "Essex & Suffolk Water",
  "IG4": "Thames Water",
  "IG5": "Thames Water",
  "IG6": "Essex & Suffolk Water",
  "IG7": "Thames Water",
  "IG8": "Thames Water",
  "IG9": "Thames Water",
  "IM1": "Manx Utilities",
  "IM2": "Manx Utilities",
  "IM3": "Manx Utilities",
  "IM4": "Manx Utilities",
  "IM5": "Manx Utilities",
  "IM6": "Manx Utilities",
  "IM7": "Manx Utilities",
  "IM8": "Manx Utilities",
  "IM9": "Manx Utilities",
  "IM99": "Manx Utilities",
  "SA36": "Dwr Cymru Welsh Water",
  "SA37": "Dwr Cymru Welsh Water",
  "SA38": "Dwr Cymru Welsh Water",
  "SA39": "Dwr Cymru Welsh Water",
  "SA4": "Dwr Cymru Welsh Water",
  "SA40": "Dwr Cymru Welsh Water",
  "IP15": "Essex & Suffolk Water",
  "IP16": "Essex & Suffolk Water",
  "IP17": "Essex & Suffolk Water",
  "IP18": "Essex & Suffolk Water",
  "IP19": "Essex & Suffolk Water",
  "SA41": "Dwr Cymru Welsh Water",
  "SA42": "Dwr Cymru Welsh Water",
  "SA43": "Dwr Cymru Welsh Water",
  "SA44": "Dwr Cymru Welsh Water",
  "IP23": "Essex & Suffolk Water",
  "SA45": "Dwr Cymru Welsh Water",
  "SA46": "Dwr Cymru Welsh Water",
  "SA47": "Dwr Cymru Welsh Water",
  "SA48": "Dwr Cymru Welsh Water",
  "SA5": "Dwr Cymru Welsh Water",
  "SA6": "Dwr Cymru Welsh Water",
  "SA61": "Dwr Cymru Welsh Water",
  "SA62": "Dwr Cymru Welsh Water",
  "SA63": "Dwr Cymru Welsh Water",
  "SA64": "Dwr Cymru Welsh Water",
  "SA65": "Dwr Cymru Welsh Water",
  "SA66": "Dwr Cymru Welsh Water",
  "SA67": "Dwr Cymru Welsh Water",
  "SA68": "Dwr Cymru Welsh Water",
  "SA69": "Dwr Cymru Welsh Water",
  "SA7": "Dwr Cymru Welsh Water",
  "SA70": "Dwr Cymru Welsh Water",
  "IP98": "Manx Utilities",
  "IV1": "Scottish Water",
  "IV10": "Scottish Water",
  "IV11": "Scottish Water",
  "IV12": "Scottish Water",
  "IV13": "Scottish Water",
  "IV14": "Scottish Water",
  "IV15": "Scottish Water",
  "IV16": "Scottish Water",
  "IV17": "Scottish Water",
  "IV18": "Scottish Water",
  "IV19": "Scottish Water",
  "IV2": "Scottish Water",
  "IV20": "Scottish Water",
  "IV21": "Scottish Water",
  "IV22": "Scottish Water",
  "IV23": "Scottish Water",
  "IV24": "Scottish Water",
  "IV25": "Scottish Water",
  "IV26": "Scottish Water",
  "IV27": "Scottish Water",
  "IV28": "Scottish Water",
  "IV3": "Scottish Water",
  "IV30": "Scottish Water",
  "IV31": "Scottish Water",
  "IV32": "Scottish Water",
  "IV36": "Scottish Water",
  "IV4": "Scottish Water",
  "IV40": "Scottish Water",
  "IV41": "Scottish Water",
  "IV42": "Scottish Water",
  "IV43": "Scottish Water",
  "IV44": "Scottish Water",
  "IV45": "Scottish Water",
  "IV46": "Scottish Water",
  "IV47": "Scottish Water",
  "IV48": "Scottish Water",
  "IV49": "Scottish Water",
  "IV5": "Scottish Water",
  "IV51": "Scottish Water",
  "IV52": "Scottish Water",
  "IV53": "Scottish Water",
  "IV54": "Scottish Water",
  "IV55": "Scottish Water",
  "IV56": "Scottish Water",
  "IV6": "Scottish Water",
  "IV63": "Scottish Water",
  "IV7": "Scottish Water",
  "IV8": "Scottish Water",
  "IV9": "Scottish Water",
  "IV99": "Scottish Water",
  "JE1": "Jersey Water",
  "JE2": "Jersey Water",
  "JE3": "Jersey Water",
  "JE4": "Jersey Water",
  "JE5": "Jersey Water",
  "SA71": "Dwr Cymru Welsh Water",
  "SA72": "Dwr Cymru Welsh Water",
  "SA73": "Dwr Cymru Welsh Water",
  "SA8": "Dwr Cymru Welsh Water",
  "SA80": "Dwr Cymru Welsh Water",
  "SA9": "Dwr Cymru Welsh Water",
  "SA99": "Dwr Cymru Welsh Water",
  "SY20": "Dwr Cymru Welsh Water",
  "SY23": "Dwr Cymru Welsh Water",
  "SY24": "Dwr Cymru Welsh Water",
  "SY25": "Dwr Cymru Welsh Water",
  "NR30": "Essex & Suffolk Water",
  "RM1": "Essex & Suffolk Water",
  "RM10": "Essex & Suffolk Water",
  "RM11": "Essex & Suffolk Water",
  "RM12": "Essex & Suffolk Water",
  "RM13": "Essex & Suffolk Water",
  "RM14": "Essex & Suffolk Water",
  "RM15": "Essex & Suffolk Water",
  "RM16": "Essex & Suffolk Water",
  "RM17": "Essex & Suffolk Water",
  "RM18": "Essex & Suffolk Water",
  "RM19": "Essex & Suffolk Water",
  "RM2": "Essex & Suffolk Water",
  "RM20": "Essex & Suffolk Water",
  "RM3": "Essex & Suffolk Water",
  "RM5": "Essex & Suffolk Water",
  "RM6": "Essex & Suffolk Water",
  "RM7": "Essex & Suffolk Water",
  "RM8": "Essex & Suffolk Water",
  "RM9": "Essex & Suffolk Water",
  "SS0": "Essex & Suffolk Water",
  "SS1": "Essex & Suffolk Water",
  "SS11": "Essex & Suffolk Water",
  "SS12": "Essex & Suffolk Water",
  "KT14": "Thames Water",
  "SS13": "Essex & Suffolk Water",
  "SS14": "Essex & Suffolk Water",
  "SS15": "Essex & Suffolk Water",
  "SS16": "Essex & Suffolk Water",
  "SS17": "Essex & Suffolk Water",
  "SS2": "Essex & Suffolk Water",
  "SS22": "Essex & Suffolk Water",
  "SS3": "Essex & Suffolk Water",
  "SS4": "Essex & Suffolk Water",
  "SS5": "Essex & Suffolk Water",
  "SS6": "Essex & Suffolk Water",
  "SS7": "Essex & Suffolk Water",
  "SS8": "Essex & Suffolk Water",
  "SS9": "Essex & Suffolk Water",
  "TS24": "Hartlepool Water",
  "TS25": "Hartlepool Water",
  "TS26": "Hartlepool Water",
  "TS27": "Hartlepool Water",
  "KW1": "Scottish Water",
  "KW10": "Scottish Water",
  "KW11": "Scottish Water",
  "KW12": "Scottish Water",
  "KW13": "Scottish Water",
  "KW14": "Scottish Water",
  "KW15": "Scottish Water",
  "KW16": "Scottish Water",
  "KW17": "Scottish Water",
  "KW2": "Scottish Water",
  "KW3": "Scottish Water",
  "KW5": "Scottish Water",
  "KW6": "Scottish Water",
  "KW7": "Scottish Water",
  "KW8": "Scottish Water",
  "KW9": "Scottish Water",
  "KY1": "Scottish Water",
  "KY10": "Scottish Water",
  "KY11": "Scottish Water",
  "KY12": "Scottish Water",
  "KY13": "Scottish Water",
  "KY14": "Scottish Water",
  "KY15": "Scottish Water",
  "KY16": "Scottish Water",
  "KY2": "Scottish Water",
  "KY3": "Scottish Water",
  "KY4": "Scottish Water",
  "KY5": "Scottish Water",
  "KY6": "Scottish Water",
  "KY7": "Scottish Water",
  "KY8": "Scottish Water",
  "KY9": "Scottish Water",
  "KY99": "Scottish Water",
  "DH1": "Northumbrian Water",
  "DH2": "Northumbrian Water",
  "DH3": "Northumbrian Water",
  "DH4": "Northumbrian Water",
  "DH5": "Northumbrian Water",
  "DH6": "Northumbrian Water",
  "DH7": "Northumbrian Water",
  "DH8": "Northumbrian Water",
  "DH9": "Northumbrian Water",
  "DH97": "Northumbrian Water",
  "DH98": "Northumbrian Water",
  "DH99": "Northumbrian Water",
  "DL1": "Northumbrian Water",
  "DL12": "Northumbrian Water",
  "DL13": "Northumbrian Water",
  "DL14": "Northumbrian Water",
  "DL15": "Northumbrian Water",
  "DL16": "Northumbrian Water",
  "DL17": "Northumbrian Water",
  "DL2": "Northumbrian Water",
  "DL3": "Northumbrian Water",
  "DL4": "Northumbrian Water",
  "DL5": "Northumbrian Water",
  "NE1": "Northumbrian Water",
  "NE10": "Northumbrian Water",
  "NE11": "Northumbrian Water",
  "NE12": "Northumbrian Water",
  "NE13": "Northumbrian Water",
  "NE15": "Northumbrian Water",
  "NE16": "Northumbrian Water",
  "NE17": "Northumbrian Water",
  "NE18": "Northumbrian Water",
  "NE19": "Northumbrian Water",
  "NE2": "Northumbrian Water",
  "NE20": "Northumbrian Water",
  "NE21": "Northumbrian Water",
  "NE22": "Northumbrian Water",
  "NE23": "Northumbrian Water",
  "NE24": "Northumbrian Water",
  "NE25": "Northumbrian Water",
  "NE26": "Northumbrian Water",
  "NE27": "Northumbrian Water",
  "NE28": "Northumbrian Water",
  "NE29": "Northumbrian Water",
  "NE3": "Northumbrian Water",
  "NE30": "Northumbrian Water",
  "NE31": "Northumbrian Water",
  "NE32": "Northumbrian Water",
  "NE33": "Northumbrian Water",
  "NE34": "Northumbrian Water",
  "NE35": "Northumbrian Water",
  "NE36": "Northumbrian Water",
  "NE37": "Northumbrian Water",
  "NE38": "Northumbrian Water",
  "NE39": "Northumbrian Water",
  "NE4": "Northumbrian Water",
  "NE40": "Northumbrian Water",
  "NE41": "Northumbrian Water",
  "NE42": "Northumbrian Water",
  "NE43": "Northumbrian Water",
  "NE44": "Northumbrian Water",
  "NE45": "Northumbrian Water",
  "NE46": "Northumbrian Water",
  "NE47": "Northumbrian Water",
  "NE48": "Northumbrian Water",
  "NE49": "Northumbrian Water",
  "NE5": "Northumbrian Water",
  "NE6": "Northumbrian Water",
  "NE61": "Northumbrian Water",
  "NE62": "Northumbrian Water",
  "NE63": "Northumbrian Water",
  "NE64": "Northumbrian Water",
  "LD1": "Dwr Cymru Welsh Water",
  "LD2": "Dwr Cymru Welsh Water",
  "LD3": "Dwr Cymru Welsh Water",
  "LD4": "Dwr Cymru Welsh Water",
  "LD5": "Dwr Cymru Welsh Water",
  "LD6": "Dwr Cymru Welsh Water",
  "LD7": "Dwr Cymru Welsh Water",
  "LD8": "Dwr Cymru Welsh Water",
  "LE1": "Severn Trent",
  "LE10": "Severn Trent",
  "LE11": "Severn Trent",
  "LE12": "Severn Trent",
  "LE13": "Severn Trent",
  "LE14": "Severn Trent",
  "LE15": "Severn Trent",
  "LE16": "Severn Trent",
  "LE17": "Severn Trent",
  "LE18": "Severn Trent",
  "LE19": "Severn Trent",
  "LE2": "Severn Trent",
  "NE65": "Northumbrian Water",
  "LE3": "Severn Trent",
  "LE4": "Severn Trent",
  "NE66": "Northumbrian Water",
  "LE5": "Severn Trent",
  "NE67": "Northumbrian Water",
  "LE6": "Severn Trent",
  "LE65": "Severn Trent",
  "NE68": "Northumbrian Water",
  "LE7": "Severn Trent",
  "LE8": "Severn Trent",
  "LE87": "Severn Trent",
  "LE9": "Severn Trent",
  "NE69": "Northumbrian Water",
  "NE7": "Northumbrian Water",
  "NE70": "Northumbrian Water",
  "NE71": "Northumbrian Water",
  "NE8": "Northumbrian Water",
  "NE82": "Northumbrian Water",
  "NE83": "Northumbrian Water",
  "NE85": "Northumbrian Water",
  "NE88": "Northumbrian Water",
  "NE9": "Northumbrian Water",
  "NE92": "Northumbrian Water",
  "NE98": "Northumbrian Water",
  "NE99": "Northumbrian Water",
  "SR1": "Northumbrian Water",
  "SR2": "Northumbrian Water",
  "LL24": "Dwr Cymru Welsh Water",
  "SR3": "Northumbrian Water",
  "SR4": "Northumbrian Water",
  "SR5": "Northumbrian Water",
  "SR6": "Northumbrian Water",
  "SR7": "Northumbrian Water",
  "SR8": "Northumbrian Water",
  "SR9": "Northumbrian Water",
  "TS1": "Northumbrian Water",
  "TS10": "Northumbrian Water",
  "TS11": "Northumbrian Water",
  "TS12": "Northumbrian Water",
  "TS13": "Northumbrian Water",
  "TS14": "Northumbrian Water",
  "TS15": "Northumbrian Water",
  "TS16": "Northumbrian Water",
  "LL40": "Dwr Cymru Welsh Water",
  "TS17": "Northumbrian Water",
  "TS18": "Northumbrian Water",
  "TS19": "Northumbrian Water",
  "TS2": "Northumbrian Water",
  "TS20": "Northumbrian Water",
  "TS21": "Northumbrian Water",
  "TS22": "Northumbrian Water",
  "TS23": "Northumbrian Water",
  "TS28": "Northumbrian Water",
  "TS29": "Northumbrian Water",
  "TS3": "Northumbrian Water",
  "TS4": "Northumbrian Water",
  "TS5": "Northumbrian Water",
  "TS6": "Northumbrian Water",
  "TS7": "Northumbrian Water",
  "TS8": "Northumbrian Water",
  "TS9": "Northumbrian Water",
  "PO1": "Portsmouth Water",
  "LL60": "Dwr Cymru Welsh Water",
  "PO10": "Portsmouth Water",
  "PO11": "Portsmouth Water",
  "PO12": "Portsmouth Water",
  "PO13": "Portsmouth Water",
  "PO14": "Portsmouth Water",
  "PO16": "Portsmouth Water",
  "PO17": "Portsmouth Water",
  "PO18": "Portsmouth Water",
  "PO19": "Portsmouth Water",
  "PO2": "Portsmouth Water",
  "PO20": "Portsmouth Water",
  "PO21": "Portsmouth Water",
  "PO22": "Portsmouth Water",
  "PO3": "Portsmouth Water",
  "PO4": "Portsmouth Water",
  "PO5": "Portsmouth Water",
  "PO6": "Portsmouth Water",
  "PO7": "Portsmouth Water",
  "LN1": "Anglian Water",
  "PO8": "Portsmouth Water",
  "PO9": "Portsmouth Water",
  "SO32": "Portsmouth Water",
  "DG10": "Scottish Water",
  "DG11": "Scottish Water",
  "DG12": "Scottish Water",
  "DG13": "Scottish Water",
  "DG14": "Scottish Water",
  "DG16": "Scottish Water",
  "DG2": "Scottish Water",
  "DG3": "Scottish Water",
  "DG4": "Scottish Water",
  "LS1": "Yorkshire Water",
  "LS10": "Yorkshire Water",
  "LS11": "Yorkshire Water",
  "LS12": "Yorkshire Water",
  "LS13": "Yorkshire Water",
  "LS14": "Yorkshire Water",
  "LS15": "Yorkshire Water",
  "LS16": "Yorkshire Water",
  "LS17": "Yorkshire Water",
  "LS18": "Yorkshire Water",
  "LS19": "Yorkshire Water",
  "LS2": "Yorkshire Water",
  "LS20": "Yorkshire Water",
  "LS21": "Yorkshire Water",
  "LS22": "Yorkshire Water",
  "LS23": "Yorkshire Water",
  "LS24": "Yorkshire Water",
  "LS25": "Yorkshire Water",
  "LS26": "Yorkshire Water",
  "LS27": "Yorkshire Water",
  "LS28": "Yorkshire Water",
  "LS29": "Yorkshire Water",
  "LS3": "Yorkshire Water",
  "LS4": "Yorkshire Water",
  "LS5": "Yorkshire Water",
  "LS6": "Yorkshire Water",
  "LS7": "Yorkshire Water",
  "LS8": "Yorkshire Water",
  "LS88": "Yorkshire Water",
  "LS9": "Yorkshire Water",
  "LS98": "Yorkshire Water",
  "LS99": "Yorkshire Water",
  "DG5": "Scottish Water",
  "DG6": "Scottish Water",
  "DG7": "Scottish Water",
  "DG8": "Scottish Water",
  "DG9": "Scottish Water",
  "EH1": "Scottish Water",
  "EH10": "Scottish Water",
  "EH11": "Scottish Water",
  "EH12": "Scottish Water",
  "EH13": "Scottish Water",
  "EH14": "Scottish Water",
  "EH15": "Scottish Water",
  "EH16": "Scottish Water",
  "EH17": "Scottish Water",
  "EH18": "Scottish Water",
  "EH19": "Scottish Water",
  "EH2": "Scottish Water",
  "EH20": "Scottish Water",
  "EH21": "Scottish Water",
  "EH22": "Scottish Water",
  "EH23": "Scottish Water",
  "EH24": "Scottish Water",
  "EH25": "Scottish Water",
  "EH26": "Scottish Water",
  "EH27": "Scottish Water",
  "EH28": "Scottish Water",
  "EH29": "Scottish Water",
  "EH3": "Scottish Water",
  "EH30": "Scottish Water",
  "EH31": "Scottish Water",
  "EH32": "Scottish Water",
  "EH33": "Scottish Water",
  "EH34": "Scottish Water",
  "EH35": "Scottish Water",
  "EH36": "Scottish Water",
  "EH37": "Scottish Water",
  "EH38": "Scottish Water",
  "EH39": "Scottish Water",
  "EH4": "Scottish Water",
  "EH40": "Scottish Water",
  "EH41": "Scottish Water",
  "EH42": "Scottish Water",
  "EH43": "Scottish Water",
  "EH44": "Scottish Water",
  "EH45": "Scottish Water",
  "EH46": "Scottish Water",
  "EH47": "Scottish Water",
  "EH48": "Scottish Water",
  "EH49": "Scottish Water",
  "EH5": "Scottish Water",
  "EH51": "Scottish Water",
  "EH52": "Scottish Water",
  "EH53": "Scottish Water",
  "EH54": "Scottish Water",
  "ME10": "Southern Water",
  "EH55": "Scottish Water",
  "EH6": "Scottish Water",
  "EH7": "Scottish Water",
  "EH8": "Scottish Water",
  "EH9": "Scottish Water",
  "EH91": "Scottish Water",
  "EH95": "Scottish Water",
  "EH99": "Scottish Water",
  "HS1": "Scottish Water",
  "HS2": "Scottish Water",
  "HS3": "Scottish Water",
  "HS4": "Scottish Water",
  "HS5": "Scottish Water",
  "HS6": "Scottish Water",
  "HS7": "Scottish Water",
  "HS8": "Scottish Water",
  "HS9": "Scottish Water",
  "KA1": "Scottish Water",
  "KA10": "Scottish Water",
  "KA11": "Scottish Water",
  "KA12": "Scottish Water",
  "KA13": "Scottish Water",
  "KA14": "Scottish Water",
  "KA15": "Scottish Water",
  "MK14": "Thames Water",
  "KA16": "Scottish Water",
  "KA17": "Scottish Water",
  "KA18": "Scottish Water",
  "KA19": "Scottish Water",
  "KA2": "Scottish Water",
  "KA20": "Scottish Water",
  "KA21": "Scottish Water",
  "KA22": "Scottish Water",
  "KA23": "Scottish Water",
  "KA24": "Scottish Water",
  "KA25": "Scottish Water",
  "KA26": "Scottish Water",
  "KA27": "Scottish Water",
  "KA28": "Scottish Water",
  "KA29": "Scottish Water",
  "KA3": "Scottish Water",
  "KA30": "Scottish Water",
  "KA4": "Scottish Water",
  "KA5": "Scottish Water",
  "KA6": "Scottish Water",
  "KA7": "Scottish Water",
  "KA8": "Scottish Water",
  "KA9": "Scottish Water",
  "ML1": "Scottish Water",
  "ML10": "Scottish Water",
  "ML11": "Scottish Water",
  "ML12": "Scottish Water",
  "ML2": "Scottish Water",
  "ML3": "Scottish Water",
  "ML4": "Scottish Water",
  "ML5": "Scottish Water",
  "ML6": "Scottish Water",
  "ML7": "Scottish Water",
  "ML8": "Scottish Water",
  "ML9": "Scottish Water",
  "TD1": "Scottish Water",
  "TD10": "Scottish Water",
  "TD11": "Scottish Water",
  "TD12": "Scottish Water",
  "TD13": "Scottish Water",
  "TD14": "Scottish Water",
  "TD15": "Scottish Water",
  "TD2": "Scottish Water",
  "TD3": "Scottish Water",
  "TD4": "Scottish Water",
  "TD5": "Scottish Water",
  "TD6": "Scottish Water",
  "TD7": "Scottish Water",
  "TD8": "Scottish Water",
  "TD9": "Scottish Water",
  "ZE1": "Scottish Water",
  "ZE2": "Scottish Water",
  "ZE3": "Scottish Water",
  "KT11": "SES Water",
  "KT20": "SES Water",
  "KT21": "SES Water",
  "KT22": "SES Water",
  "KT23": "SES Water",
  "KT24": "SES Water",
  "KT4": "SES Water",
  "RH1": "SES Water",
  "RH2": "SES Water",
  "RH3": "SES Water",
  "RH4": "SES Water",
  "RH5": "SES Water",
  "RH6": "SES Water",
  "RH7": "SES Water",
  "RH8": "SES Water",
  "RH9": "SES Water",
  "SM1": "SES Water",
  "SM2": "SES Water",
  "SM3": "SES Water",
  "SM4": "SES Water",
  "SM5": "SES Water",
  "SM6": "SES Water",
  "SM7": "SES Water",
  "SW20": "SES Water",
  "CV1": "Severn Trent",
  "CV10": "Severn Trent",
  "CV11": "Severn Trent",
  "CV12": "Severn Trent",
  "CV13": "Severn Trent",
  "CV2": "Severn Trent",
  "CV21": "Severn Trent",
  "CV22": "Severn Trent",
  "CV23": "Severn Trent",
  "CV3": "Severn Trent",
  "CV31": "Severn Trent",
  "CV32": "Severn Trent",
  "CV33": "Severn Trent",
  "CV34": "Severn Trent",
  "CV35": "Severn Trent",
  "CV36": "Severn Trent",
  "CV37": "Severn Trent",
  "CV4": "Severn Trent",
  "CV47": "Severn Trent",
  "CV5": "Severn Trent",
  "CV6": "Severn Trent",
  "CV7": "Severn Trent",
  "CV8": "Severn Trent",
  "CV9": "Severn Trent",
  "DE11": "Severn Trent",
  "DE12": "Severn Trent",
  "DE21": "Severn Trent",
  "DE22": "Severn Trent",
  "DE23": "Severn Trent",
  "DE24": "Severn Trent",
  "DE3": "Severn Trent",
  "DE4": "Severn Trent",
  "DE45": "Severn Trent",
  "DE5": "Severn Trent",
  "DE55": "Severn Trent",
  "DE56": "Severn Trent",
  "DE6": "Severn Trent",
  "DE7": "Severn Trent",
  "DE72": "Severn Trent",
  "DE73": "Severn Trent",
  "DE74": "Severn Trent",
  "DE75": "Severn Trent",
  "DY10": "Severn Trent",
  "DY11": "Severn Trent",
  "DY12": "Severn Trent",
  "DY13": "Severn Trent",
  "DY14": "Severn Trent",
  "DY8": "Severn Trent",
  "NG1": "Severn Trent",
  "NG10": "Severn Trent",
  "NG11": "Severn Trent",
  "NG12": "Severn Trent",
  "NG13": "Severn Trent",
  "NG14": "Severn Trent",
  "NG15": "Severn Trent",
  "NG16": "Severn Trent",
  "NG17": "Severn Trent",
  "NG18": "Severn Trent",
  "NG19": "Severn Trent",
  "NG2": "Severn Trent",
  "NG20": "Severn Trent",
  "NG21": "Severn Trent",
  "NG22": "Severn Trent",
  "NG23": "Severn Trent",
  "NG24": "Severn Trent",
  "NG25": "Severn Trent",
  "NG3": "Severn Trent",
  "NG4": "Severn Trent",
  "NG5": "Severn Trent",
  "NG6": "Severn Trent",
  "NG7": "Severn Trent",
  "NG70": "Severn Trent",
  "NG8": "Severn Trent",
  "NG80": "Severn Trent",
  "NG9": "Severn Trent",
  "NG90": "Severn Trent",
  "S18": "Severn Trent",
  "S21": "Severn Trent",
  "S32": "Severn Trent",
  "S33": "Severn Trent",
  "S40": "Severn Trent",
  "S41": "Severn Trent",
  "S42": "Severn Trent",
  "S44": "Severn Trent",
  "S45": "Severn Trent",
  "S80": "Severn Trent",
  "S81": "Severn Trent",
  "NN18": "Independent Water Networks",
  "SK17": "Severn Trent",
  "SK23": "Severn Trent",
  "ST1": "Severn Trent",
  "ST10": "Severn Trent",
  "ST11": "Severn Trent",
  "ST12": "Severn Trent",
  "ST13": "Severn Trent",
  "ST14": "Severn Trent",
  "ST15": "Severn Trent",
  "NP10": "Dwr Cymru Welsh Water",
  "NP11": "Dwr Cymru Welsh Water",
  "NP12": "Dwr Cymru Welsh Water",
  "NP13": "Dwr Cymru Welsh Water",
  "NP15": "Dwr Cymru Welsh Water",
  "NP16": "Dwr Cymru Welsh Water",
  "NP18": "Dwr Cymru Welsh Water",
  "NP19": "Dwr Cymru Welsh Water",
  "NP20": "Dwr Cymru Welsh Water",
  "NP22": "Dwr Cymru Welsh Water",
  "NP23": "Dwr Cymru Welsh Water",
  "NP24": "Dwr Cymru Welsh Water",
  "NP25": "Dwr Cymru Welsh Water",
  "NP26": "Dwr Cymru Welsh Water",
  "NP4": "Dwr Cymru Welsh Water",
  "NP44": "Dwr Cymru Welsh Water",
  "NP7": "Dwr Cymru Welsh Water",
  "NP8": "Dwr Cymru Welsh Water",
  "ST16": "Severn Trent",
  "ST17": "Severn Trent",
  "ST18": "Severn Trent",
  "ST2": "Severn Trent",
  "ST20": "Severn Trent",
  "ST21": "Severn Trent",
  "ST3": "Severn Trent",
  "ST4": "Severn Trent",
  "ST5": "Severn Trent",
  "ST6": "Severn Trent",
  "ST7": "Severn Trent",
  "ST8": "Severn Trent",
  "ST9": "Severn Trent",
  "SY1": "Severn Trent",
  "SY10": "Severn Trent",
  "SY11": "Severn Trent",
  "SY12": "Severn Trent",
  "SY13": "Severn Trent",
  "SY2": "Severn Trent",
  "SY3": "Severn Trent",
  "SY4": "Severn Trent",
  "SY5": "Severn Trent",
  "SY6": "Severn Trent",
  "SY7": "Severn Trent",
  "NR31": "Essex & Suffolk Water",
  "NR32": "Essex & Suffolk Water",
  "SY8": "Severn Trent",
  "NR34": "Essex & Suffolk Water",
  "SY9": "Severn Trent",
  "SY99": "Severn Trent",
  "TF1": "Severn Trent",
  "TF10": "Severn Trent",
  "TF11": "Severn Trent",
  "TF12": "Severn Trent",
  "TF13": "Severn Trent",
  "TF2": "Severn Trent",
  "TF3": "Severn Trent",
  "TF4": "Severn Trent",
  "TF5": "Severn Trent",
  "TF6": "Severn Trent",
  "TF7": "Severn Trent",
  "TF8": "Severn Trent",
  "TF9": "Severn Trent",
  "WR1": "Severn Trent",
  "WR10": "Severn Trent",
  "WR11": "Severn Trent",
  "WR12": "Severn Trent",
  "WR13": "Severn Trent",
  "WR14": "Severn Trent",
  "WR15": "Severn Trent",
  "WR2": "Severn Trent",
  "WR3": "Severn Trent",
  "WR4": "Severn Trent",
  "WR5": "Severn Trent",
  "WR6": "Severn Trent",
  "WR7": "Severn Trent",
  "WR8": "Severn Trent",
  "WR9": "Severn Trent",
  "WR99": "Severn Trent",
  "WV1": "Severn Trent",
  "WV10": "Severn Trent",
  "WV11": "Severn Trent",
  "WV12": "Severn Trent",
  "WV13": "Severn Trent",
  "WV14": "Severn Trent",
  "WV15": "Severn Trent",
  "WV16": "Severn Trent",
  "WV2": "Severn Trent",
  "WV3": "Severn Trent",
  "WV4": "Severn Trent",
  "WV5": "Severn Trent",
  "WV6": "Severn Trent",
  "WV7": "Severn Trent",
  "WV8": "Severn Trent",
  "WV9": "Severn Trent",
  "CT1": "South East Water",
  "CT2": "South East Water",
  "CT5": "South East Water",
  "CT50": "South East Water",
  "CT6": "South East Water",
  "DA3": "South East Water",
  "GU10": "South East Water",
  "GU11": "South East Water",
  "GU12": "South East Water",
  "GU14": "South East Water",
  "GU15": "South East Water",
  "GU16": "South East Water",
  "GU17": "South East Water",
  "GU26": "South East Water",
  "GU27": "South East Water",
  "GU30": "South East Water",
  "GU32": "South East Water",
  "PA1": "Scottish Water",
  "PA10": "Scottish Water",
  "PA11": "Scottish Water",
  "PA12": "Scottish Water",
  "PA13": "Scottish Water",
  "PA14": "Scottish Water",
  "PA15": "Scottish Water",
  "PA16": "Scottish Water",
  "PA17": "Scottish Water",
  "PA18": "Scottish Water",
  "PA19": "Scottish Water",
  "PA2": "Scottish Water",
  "PA20": "Scottish Water",
  "PA21": "Scottish Water",
  "PA22": "Scottish Water",
  "PA23": "Scottish Water",
  "PA24": "Scottish Water",
  "PA25": "Scottish Water",
  "PA26": "Scottish Water",
  "PA27": "Scottish Water",
  "PA28": "Scottish Water",
  "PA29": "Scottish Water",
  "PA3": "Scottish Water",
  "PA30": "Scottish Water",
  "PA31": "Scottish Water",
  "PA32": "Scottish Water",
  "PA33": "Scottish Water",
  "PA34": "Scottish Water",
  "PA35": "Scottish Water",
  "PA36": "Scottish Water",
  "PA37": "Scottish Water",
  "PA38": "Scottish Water",
  "PA4": "Scottish Water",
  "PA41": "Scottish Water",
  "PA42": "Scottish Water",
  "PA43": "Scottish Water",
  "PA44": "Scottish Water",
  "PA45": "Scottish Water",
  "PA46": "Scottish Water",
  "PA47": "Scottish Water",
  "PA48": "Scottish Water",
  "PA49": "Scottish Water",
  "PA5": "Scottish Water",
  "PA6": "Scottish Water",
  "PA60": "Scottish Water",
  "PA61": "Scottish Water",
  "PA62": "Scottish Water",
  "PA63": "Scottish Water",
  "PA64": "Scottish Water",
  "PA65": "Scottish Water",
  "PA66": "Scottish Water",
  "PA67": "Scottish Water",
  "PA68": "Scottish Water",
  "PA69": "Scottish Water",
  "PA7": "Scottish Water",
  "PA70": "Scottish Water",
  "PA71": "Scottish Water",
  "PA72": "Scottish Water",
  "PA73": "Scottish Water",
  "PA74": "Scottish Water",
  "PA75": "Scottish Water",
  "PA76": "Scottish Water",
  "PA77": "Scottish Water",
  "PA78": "Scottish Water",
  "PA8": "Scottish Water",
  "PA9": "Scottish Water",
  "GU33": "South East Water",
  "GU34": "South East Water",
  "GU46": "South East Water",
  "GU47": "South East Water",
  "ME13": "South East Water",
  "ME14": "South East Water",
  "ME15": "South East Water",
  "ME16": "South East Water",
  "ME17": "South East Water",
  "ME18": "South East Water",
  "ME19": "South East Water",
  "ME20": "South East Water",
  "ME6": "South East Water",
  "RG12": "South East Water",
  "RG21": "South East Water",
  "RG22": "South East Water",
  "PE26": "Cambridge Water",
  "PE27": "Cambridge Water",
  "RG23": "South East Water",
  "RG24": "South East Water",
  "RG25": "South East Water",
  "RG26": "South East Water",
  "RG27": "South East Water",
  "RG29": "South East Water",
  "RG42": "South East Water",
  "RG45": "South East Water",
  "RH15": "South East Water",
  "RH16": "South East Water",
  "RH17": "South East Water",
  "RH18": "South East Water",
  "RH19": "South East Water",
  "SL6": "South East Water",
  "TN1": "South East Water",
  "TN10": "South East Water",
  "TN11": "South East Water",
  "TN12": "South East Water",
  "TN13": "South East Water",
  "PH1": "Scottish Water",
  "PH10": "Scottish Water",
  "PH11": "Scottish Water",
  "PH12": "Scottish Water",
  "PH13": "Scottish Water",
  "PH14": "Scottish Water",
  "PH15": "Scottish Water",
  "PH16": "Scottish Water",
  "PH17": "Scottish Water",
  "PH18": "Scottish Water",
  "PH19": "Scottish Water",
  "PH2": "Scottish Water",
  "PH20": "Scottish Water",
  "PH21": "Scottish Water",
  "PH22": "Scottish Water",
  "PH23": "Scottish Water",
  "PH24": "Scottish Water",
  "PH25": "Scottish Water",
  "PH26": "Scottish Water",
  "PH3": "Scottish Water",
  "PH30": "Scottish Water",
  "PH31": "Scottish Water",
  "PH32": "Scottish Water",
  "PH33": "Scottish Water",
  "PH34": "Scottish Water",
  "PH35": "Scottish Water",
  "PH36": "Scottish Water",
  "PH37": "Scottish Water",
  "PH38": "Scottish Water",
  "PH39": "Scottish Water",
  "PH4": "Scottish Water",
  "PH40": "Scottish Water",
  "PH41": "Scottish Water",
  "PH42": "Scottish Water",
  "PH43": "Scottish Water",
  "PH44": "Scottish Water",
  "PH49": "Scottish Water",
  "PH5": "Scottish Water",
  "PH50": "Scottish Water",
  "PH6": "Scottish Water",
  "PH7": "Scottish Water",
  "PH8": "Scottish Water",
  "PH9": "Scottish Water",
  "TN15": "South East Water",
  "TN17": "South East Water",
  "TN18": "South East Water",
  "TN19": "South East Water",
  "TN2": "South East Water",
  "TN20": "South East Water",
  "TN21": "South East Water",
  "TN22": "South East Water",
  "TN23": "South East Water",
  "TN24": "South East Water",
  "TN25": "South East Water",
  "TN26": "South East Water",
  "TN27": "South East Water",
  "TN3": "South East Water",
  "TN30": "South East Water",
  "TN32": "South East Water",
  "TN33": "South East Water",
  "TN39": "South East Water",
  "TN4": "South East Water",
  "TN40": "South East Water",
  "TN5": "South East Water",
  "TN6": "South East Water",
  "TN7": "South East Water",
  "TN8": "South East Water",
  "TN9": "South East Water",
  "DE13": "South Staffordshire Water",
  "DE65": "South Staffordshire Water",
  "DY2": "South Staffordshire Water",
  "DY3": "South Staffordshire Water",
  "DY5": "South Staffordshire Water",
  "DY6": "South Staffordshire Water",
  "DY7": "South Staffordshire Water",
  "ST19": "South Staffordshire Water",
  "WS1": "South Staffordshire Water",
  "WS11": "South Staffordshire Water",
  "WS12": "South Staffordshire Water",
  "WS14": "South Staffordshire Water",
  "WS15": "South Staffordshire Water",
  "WS2": "South Staffordshire Water",
  "WS3": "South Staffordshire Water",
  "WS4": "South Staffordshire Water",
  "WS5": "South Staffordshire Water",
  "WS6": "South Staffordshire Water",
  "WS8": "South Staffordshire Water",
  "WS9": "South Staffordshire Water",
  "PL1": "South West Water",
  "PL10": "South West Water",
  "PL11": "South West Water",
  "PL12": "South West Water",
  "PL13": "South West Water",
  "PL14": "South West Water",
  "PL15": "South West Water",
  "PL16": "South West Water",
  "PL17": "South West Water",
  "PL18": "South West Water",
  "PL19": "South West Water",
  "PL2": "South West Water",
  "PL20": "South West Water",
  "PL21": "South West Water",
  "PL22": "South West Water",
  "PL23": "South West Water",
  "PL24": "South West Water",
  "PL25": "South West Water",
  "PL26": "South West Water",
  "PL27": "South West Water",
  "PL28": "South West Water",
  "PL29": "South West Water",
  "PL3": "South West Water",
  "PL30": "South West Water",
  "PL31": "South West Water",
  "PL32": "South West Water",
  "PL33": "South West Water",
  "PL34": "South West Water",
  "PL35": "South West Water",
  "PL4": "South West Water",
  "PL5": "South West Water",
  "PL6": "South West Water",
  "PL7": "South West Water",
  "PL8": "South West Water",
  "PL9": "South West Water",
  "PL95": "South West Water",
  "TA22": "South West Water",
  "TQ1": "South West Water",
  "TQ10": "South West Water",
  "TQ11": "South West Water",
  "TQ12": "South West Water",
  "TQ13": "South West Water",
  "TQ14": "South West Water",
  "TQ2": "South West Water",
  "TQ3": "South West Water",
  "TQ4": "South West Water",
  "TQ5": "South West Water",
  "TQ6": "South West Water",
  "TQ7": "South West Water",
  "TQ8": "South West Water",
  "TQ9": "South West Water",
  "TR1": "South West Water",
  "TR10": "South West Water",
  "TR11": "South West Water",
  "TR12": "South West Water",
  "TR13": "South West Water",
  "TR14": "South West Water",
  "TR15": "South West Water",
  "TR16": "South West Water",
  "TR17": "South West Water",
  "TR18": "South West Water",
  "TR19": "South West Water",
  "TR2": "South West Water",
  "TR20": "South West Water",
  "TR21": "South West Water",
  "TR22": "South West Water",
  "TR23": "South West Water",
  "TR24": "South West Water",
  "TR25": "South West Water",
  "TR26": "South West Water",
  "TR27": "South West Water",
  "TR3": "South West Water",
  "TR4": "South West Water",
  "TR5": "South West Water",
  "TR6": "South West Water",
  "TR7": "South West Water",
  "TR8": "South West Water",
  "TR9": "South West Water",
  "CT10": "Southern Water",
  "CT11": "Southern Water",
  "CT13": "Southern Water",
  "CT14": "Southern Water",
  "CT7": "Southern Water",
  "CT9": "Southern Water",
  "DA12": "Southern Water",
  "GU28": "Southern Water",
  "GU29": "Southern Water",
  "GU31": "Southern Water",
  "ME1": "Southern Water",
  "ME11": "Southern Water",
  "ME12": "Southern Water",
  "ME2": "Southern Water",
  "ME3": "Southern Water",
  "ME4": "Southern Water",
  "ME5": "Southern Water",
  "ME7": "Southern Water",
  "ME8": "Southern Water",
  "ME9": "Southern Water",
  "PO15": "Southern Water",
  "PO30": "Southern Water",
  "PO31": "Southern Water",
  "PO32": "Southern Water",
  "PO33": "Southern Water",
  "PO34": "Southern Water",
  "PO35": "Southern Water",
  "PO36": "Southern Water",
  "PO37": "Southern Water",
  "PO38": "Southern Water",
  "PO39": "Southern Water",
  "PO40": "Southern Water",
  "PO41": "Southern Water",
  "RG20": "Southern Water",
  "RG28": "Southern Water",
  "RH10": "Southern Water",
  "RH11": "Southern Water",
  "RH12": "Southern Water",
  "RH13": "Southern Water",
  "RH14": "Southern Water",
  "RH20": "Southern Water",
  "SO14": "Southern Water",
  "S25": "Yorkshire Water",
  "SO15": "Southern Water",
  "SO16": "Southern Water",
  "SO17": "Southern Water",
  "SO18": "Southern Water",
  "SO19": "Southern Water",
  "SO20": "Southern Water",
  "SO21": "Southern Water",
  "SO22": "Southern Water",
  "SO23": "Southern Water",
  "SO24": "Southern Water",
  "SO30": "Southern Water",
  "SO31": "Southern Water",
  "SO40": "Southern Water",
  "SO42": "Southern Water",
  "SO43": "Southern Water",
  "SO45": "Southern Water",
  "SO50": "Southern Water",
  "SO51": "Southern Water",
  "SO52": "Southern Water",
  "SO53": "Southern Water",
  "SP10": "Southern Water",
  "SP11": "Southern Water",
  "S66": "Yorkshire Water",
  "TN31": "Southern Water",
  "TN34": "Southern Water",
  "TN35": "Southern Water",
  "TN36": "Southern Water",
  "TN37": "Southern Water",
  "TN38": "Southern Water",
  "DA13": "Thames Water",
  "DA14": "Thames Water",
  "DA15": "Thames Water",
  "DA16": "Thames Water",
  "DA17": "Thames Water",
  "DA18": "Thames Water",
  "DA2": "Thames Water",
  "DA4": "Thames Water",
  "DA5": "Thames Water",
  "DA6": "Thames Water",
  "DA7": "Thames Water",
  "DA8": "Thames Water",
  "DA9": "Thames Water",
  "E1": "Thames Water",
  "E10": "Thames Water",
  "E11": "Thames Water",
  "E12": "Thames Water",
  "E13": "Thames Water",
  "E14": "Thames Water",
  "E15": "Thames Water",
  "E16": "Thames Water",
  "E17": "Thames Water",
  "E18": "Thames Water",
  "E1W": "Thames Water",
  "E2": "Thames Water",
  "E3": "Thames Water",
  "E4": "Thames Water",
  "E5": "Thames Water",
  "E6": "Thames Water",
  "E7": "Thames Water",
  "E8": "Thames Water",
  "E9": "Thames Water",
  "E98": "Thames Water",
  "EC1A": "Thames Water",
  "EC1M": "Thames Water",
  "EC1N": "Thames Water",
  "EC1R": "Thames Water",
  "EC1V": "Thames Water",
  "EC1Y": "Thames Water",
  "EC2A": "Thames Water",
  "EC2M": "Thames Water",
  "EC2N": "Thames Water",
  "EC2R": "Thames Water",
  "EC2V": "Thames Water",
  "EC2Y": "Thames Water",
  "EC3A": "Thames Water",
  "EC3M": "Thames Water",
  "EC3N": "Thames Water",
  "EC3P": "Thames Water",
  "EC3R": "Thames Water",
  "EC3V": "Thames Water",
  "EC4A": "Thames Water",
  "EC4M": "Thames Water",
  "EC4N": "Thames Water",
  "EC4P": "Thames Water",
  "EC4R": "Thames Water",
  "EC4V": "Thames Water",
  "EC4Y": "Thames Water",
  "EN1": "Thames Water",
  "EN10": "Thames Water",
  "EN11": "Thames Water",
  "EN2": "Thames Water",
  "EN3": "Thames Water",
  "GL54": "Thames Water",
  "GL55": "Thames Water",
  "GL56": "Thames Water",
  "GL7": "Thames Water",
  "GL8": "Thames Water",
  "GU1": "Thames Water",
  "GU2": "Thames Water",
  "GU3": "Thames Water",
  "GU5": "Thames Water",
  "KT1": "Thames Water",
  "KT10": "Thames Water",
  "KT17": "Thames Water",
  "KT18": "Thames Water",
  "KT19": "Thames Water",
  "KT2": "Thames Water",
  "KT3": "Thames Water",
  "KT5": "Thames Water",
  "KT6": "Thames Water",
  "KT7": "Thames Water",
  "KT8": "Thames Water",
  "KT9": "Thames Water",
  "N1": "Thames Water",
  "N10": "Thames Water",
  "N13": "Thames Water",
  "N14": "Thames Water",
  "N15": "Thames Water",
  "N16": "Thames Water",
  "N17": "Thames Water",
  "N18": "Thames Water",
  "N19": "Thames Water",
  "N1P": "Thames Water",
  "N21": "Thames Water",
  "N22": "Thames Water",
  "N4": "Thames Water",
  "N5": "Thames Water",
  "N6": "Thames Water",
  "N7": "Thames Water",
  "N8": "Thames Water",
  "N81": "Thames Water",
  "N9": "Thames Water",
  "NW1": "Thames Water",
  "NW10": "Thames Water",
  "NW11": "Thames Water",
  "NW1W": "Thames Water",
  "NW2": "Thames Water",
  "NW3": "Thames Water",
  "NW4": "Thames Water",
  "NW5": "Thames Water",
  "NW6": "Thames Water",
  "NW8": "Thames Water",
  "OX1": "Thames Water",
  "OX10": "Thames Water",
  "OX11": "Thames Water",
  "OX12": "Thames Water",
  "OX13": "Thames Water",
  "OX14": "Thames Water",
  "OX15": "Thames Water",
  "OX16": "Thames Water",
  "OX18": "Thames Water",
  "OX2": "Thames Water",
  "OX20": "Thames Water",
  "OX25": "Thames Water",
  "OX26": "Thames Water",
  "OX27": "Thames Water",
  "OX28": "Thames Water",
  "OX29": "Thames Water",
  "OX3": "Thames Water",
  "OX33": "Thames Water",
  "OX39": "Thames Water",
  "OX4": "Thames Water",
  "OX44": "Thames Water",
  "OX49": "Thames Water",
  "OX5": "Thames Water",
  "OX7": "Thames Water",
  "OX9": "Thames Water",
  "RG1": "Thames Water",
  "RG10": "Thames Water",
  "RG14": "Thames Water",
  "RG17": "Thames Water",
  "RG18": "Thames Water",
  "RG19": "Thames Water",
  "RG2": "Thames Water",
  "RG30": "Thames Water",
  "RG31": "Thames Water",
  "RG4": "Thames Water",
  "RG40": "Thames Water",
  "RG41": "Thames Water",
  "RG5": "Thames Water",
  "RG6": "Thames Water",
  "RG7": "Thames Water",
  "RG8": "Thames Water",
  "RG9": "Thames Water",
  "SE1": "Thames Water",
  "SE10": "Thames Water",
  "SE11": "Thames Water",
  "SE12": "Thames Water",
  "SE13": "Thames Water",
  "SE14": "Thames Water",
  "SE15": "Thames Water",
  "SE16": "Thames Water",
  "SE17": "Thames Water",
  "SE18": "Thames Water",
  "SE19": "Thames Water",
  "SE1P": "Thames Water",
  "SE2": "Thames Water",
  "SE20": "Thames Water",
  "SE21": "Thames Water",
  "SE22": "Thames Water",
  "SE23": "Thames Water",
  "SE24": "Thames Water",
  "SE25": "Thames Water",
  "SE26": "Thames Water",
  "SE27": "Thames Water",
  "SE28": "Thames Water",
  "SE3": "Thames Water",
  "SE4": "Thames Water",
  "SE5": "Thames Water",
  "SE6": "Thames Water",
  "SE7": "Thames Water",
  "SE8": "Thames Water",
  "SE9": "Thames Water",
  "SG13": "Thames Water",
  "SL1": "Thames Water",
  "SL2": "Thames Water",
  "SL3": "Thames Water",
  "SL4": "Thames Water",
  "SL7": "Thames Water",
  "SL8": "Thames Water",
  "SL95": "Thames Water",
  "SN1": "Thames Water",
  "SN16": "Thames Water",
  "SN2": "Thames Water",
  "SN25": "Thames Water",
  "SN26": "Thames Water",
  "SN3": "Thames Water",
  "SN4": "Thames Water",
  "SN5": "Thames Water",
  "SN6": "Thames Water",
  "SN7": "Thames Water",
  "SP9": "Veolia Water",
  "SN8": "Thames Water",
  "SN99": "Thames Water",
  "SW10": "Thames Water",
  "SW11": "Thames Water",
  "SW12": "Thames Water",
  "SW13": "Thames Water",
  "SW14": "Thames Water",
  "SW15": "Thames Water",
  "SW16": "Thames Water",
  "SW17": "Thames Water",
  "SW18": "Thames Water",
  "SW19": "Thames Water",
  "SW1A": "Thames Water",
  "SW1E": "Thames Water",
  "SW1H": "Thames Water",
  "SW1P": "Thames Water",
  "SW1V": "Thames Water",
  "SW1W": "Thames Water",
  "SW1X": "Thames Water",
  "SW1Y": "Thames Water",
  "SW2": "Thames Water",
  "SW3": "Thames Water",
  "SW4": "Thames Water",
  "SW5": "Thames Water",
  "SW6": "Thames Water",
  "SW7": "Thames Water",
  "SW8": "Thames Water",
  "SW9": "Thames Water",
  "SW95": "Thames Water",
  "TN14": "Thames Water",
  "TN16": "Thames Water",
  "TW1": "Thames Water",
  "TW10": "Thames Water",
  "TW11": "Thames Water",
  "TW12": "Thames Water",
  "TW13": "Thames Water",
  "TW16": "Thames Water",
  "TW17": "Thames Water",
  "TW19": "Thames Water",
  "TW2": "Thames Water",
  "TW3": "Thames Water",
  "TW4": "Thames Water",
  "TW5": "Thames Water",
  "TW7": "Thames Water",
  "TW8": "Thames Water",
  "TW9": "Thames Water",
  "UB7": "Thames Water",
  "W10": "Thames Water",
  "W11": "Thames Water",
  "W12": "Thames Water",
  "W13": "Thames Water",
  "W14": "Thames Water",
  "W1A": "Thames Water",
  "W1B": "Thames Water",
  "W1C": "Thames Water",
  "W1D": "Thames Water",
  "W1F": "Thames Water",
  "W1G": "Thames Water",
  "W1H": "Thames Water",
  "W1J": "Thames Water",
  "W1K": "Thames Water",
  "W1S": "Thames Water",
  "W1T": "Thames Water",
  "W1U": "Thames Water",
  "W1W": "Thames Water",
  "W2": "Thames Water",
  "W3": "Thames Water",
  "W4": "Thames Water",
  "W5": "Thames Water",
  "W6": "Thames Water",
  "W7": "Thames Water",
  "W8": "Thames Water",
  "W9": "Thames Water",
  "WC1A": "Thames Water",
  "WC1B": "Thames Water",
  "WC1E": "Thames Water",
  "WC1H": "Thames Water",
  "WC1N": "Thames Water",
  "WC1R": "Thames Water",
  "WC1V": "Thames Water",
  "WC1X": "Thames Water",
  "WC2A": "Thames Water",
  "WC2B": "Thames Water",
  "WC2E": "Thames Water",
  "WC2H": "Thames Water",
  "WC2N": "Thames Water",
  "WC2R": "Thames Water",
  "CW10": "United Utilities",
  "CW11": "United Utilities",
  "CW12": "United Utilities",
  "CW2": "United Utilities",
  "CW3": "United Utilities",
  "CW4": "United Utilities",
  "CW5": "United Utilities",
  "FY1": "United Utilities",
  "FY2": "United Utilities",
  "FY3": "United Utilities",
  "FY4": "United Utilities",
  "FY5": "United Utilities",
  "FY6": "United Utilities",
  "FY7": "United Utilities",
  "FY8": "United Utilities",
  "L1": "United Utilities",
  "L10": "United Utilities",
  "L11": "United Utilities",
  "L12": "United Utilities",
  "L13": "United Utilities",
  "L14": "United Utilities",
  "L15": "United Utilities",
  "L16": "United Utilities",
  "L17": "United Utilities",
  "L18": "United Utilities",
  "L19": "United Utilities",
  "L2": "United Utilities",
  "L20": "United Utilities",
  "L21": "United Utilities",
  "L22": "United Utilities",
  "L23": "United Utilities",
  "L24": "United Utilities",
  "L25": "United Utilities",
  "L26": "United Utilities",
  "L27": "United Utilities",
  "L28": "United Utilities",
  "L29": "United Utilities",
  "L3": "United Utilities",
  "L30": "United Utilities",
  "L31": "United Utilities",
  "L32": "United Utilities",
  "L33": "United Utilities",
  "L34": "United Utilities",
  "L35": "United Utilities",
  "L36": "United Utilities",
  "L37": "United Utilities",
  "L38": "United Utilities",
  "L39": "United Utilities",
  "L4": "United Utilities",
  "L40": "United Utilities",
  "L5": "United Utilities",
  "L6": "United Utilities",
  "L67": "United Utilities",
  "L68": "United Utilities",
  "L69": "United Utilities",
  "L7": "United Utilities",
  "L70": "United Utilities",
  "L71": "United Utilities",
  "L72": "United Utilities",
  "L73": "United Utilities",
  "L74": "United Utilities",
  "L75": "United Utilities",
  "L8": "United Utilities",
  "L9": "United Utilities",
  "LA1": "United Utilities",
  "LA10": "United Utilities",
  "LA11": "United Utilities",
  "LA12": "United Utilities",
  "LA13": "United Utilities",
  "LA14": "United Utilities",
  "LA15": "United Utilities",
  "LA16": "United Utilities",
  "LA17": "United Utilities",
  "LA18": "United Utilities",
  "LA19": "United Utilities",
  "LA2": "United Utilities",
  "LA20": "United Utilities",
  "LA21": "United Utilities",
  "LA22": "United Utilities",
  "LA23": "United Utilities",
  "LA3": "United Utilities",
  "LA4": "United Utilities",
  "LA5": "United Utilities",
  "LA6": "United Utilities",
  "LA7": "United Utilities",
  "LA8": "United Utilities",
  "LA9": "United Utilities",
  "M1": "United Utilities",
  "M11": "United Utilities",
  "M12": "United Utilities",
  "M13": "United Utilities",
  "M14": "United Utilities",
  "M15": "United Utilities",
  "M16": "United Utilities",
  "M17": "United Utilities",
  "M18": "United Utilities",
  "M19": "United Utilities",
  "M2": "United Utilities",
  "M20": "United Utilities",
  "M21": "United Utilities",
  "M22": "United Utilities",
  "M23": "United Utilities",
  "M24": "United Utilities",
  "M25": "United Utilities",
  "M26": "United Utilities",
  "M27": "United Utilities",
  "M28": "United Utilities",
  "M29": "United Utilities",
  "M3": "United Utilities",
  "M30": "United Utilities",
  "M31": "United Utilities",
  "M32": "United Utilities",
  "M33": "United Utilities",
  "M34": "United Utilities",
  "M35": "United Utilities",
  "M38": "United Utilities",
  "M4": "United Utilities",
  "M40": "United Utilities",
  "M41": "United Utilities",
  "M43": "United Utilities",
  "M44": "United Utilities",
  "M45": "United Utilities",
  "M46": "United Utilities",
  "M5": "United Utilities",
  "M50": "United Utilities",
  "M6": "United Utilities",
  "M60": "United Utilities",
  "M7": "United Utilities",
  "M8": "United Utilities",
  "M9": "United Utilities",
  "M90": "United Utilities",
  "OL1": "United Utilities",
  "OL10": "United Utilities",
  "OL11": "United Utilities",
  "OL12": "United Utilities",
  "OL13": "United Utilities",
  "OL15": "United Utilities",
  "OL16": "United Utilities",
  "OL2": "United Utilities",
  "OL3": "United Utilities",
  "OL4": "United Utilities",
  "OL5": "United Utilities",
  "OL6": "United Utilities",
  "OL7": "United Utilities",
  "OL8": "United Utilities",
  "OL9": "United Utilities",
  "PR0": "United Utilities",
  "PR1": "United Utilities",
  "PR11": "United Utilities",
  "PR2": "United Utilities",
  "PR25": "United Utilities",
  "PR26": "United Utilities",
  "PR3": "United Utilities",
  "PR4": "United Utilities",
  "PR5": "United Utilities",
  "PR6": "United Utilities",
  "PR7": "United Utilities",
  "PR8": "United Utilities",
  "PR9": "United Utilities",
  "SK1": "United Utilities",
  "SK10": "United Utilities",
  "SK11": "United Utilities",
  "SK12": "United Utilities",
  "SK13": "United Utilities",
  "SK14": "United Utilities",
  "SK15": "United Utilities",
  "SK16": "United Utilities",
  "SK2": "United Utilities",
  "SK22": "United Utilities",
  "SK3": "United Utilities",
  "SK4": "United Utilities",
  "SK5": "United Utilities",
  "SK6": "United Utilities",
  "SK7": "United Utilities",
  "SK8": "United Utilities",
  "SK9": "United Utilities",
  "SY14": "United Utilities",
  "WA1": "United Utilities",
  "WA10": "United Utilities",
  "WA11": "United Utilities",
  "WA12": "United Utilities",
  "WA13": "United Utilities",
  "WA14": "United Utilities",
  "WA15": "United Utilities",
  "WA16": "United Utilities",
  "WA2": "United Utilities",
  "WA3": "United Utilities",
  "WA4": "United Utilities",
  "WA5": "United Utilities",
  "WA55": "United Utilities",
  "WA6": "United Utilities",
  "WA7": "United Utilities",
  "WA8": "United Utilities",
  "WA88": "United Utilities",
  "WA9": "United Utilities",
  "WN1": "United Utilities",
  "WN2": "United Utilities",
  "WN3": "United Utilities",
  "WN4": "United Utilities",
  "WN5": "United Utilities",
  "WN6": "United Utilities",
  "WN7": "United Utilities",
  "WN8": "United Utilities",
  "DT1": "Wessex Water",
  "DT10": "Wessex Water",
  "DT11": "Wessex Water",
  "DT2": "Wessex Water",
  "DT3": "Wessex Water",
  "DT4": "Wessex Water",
  "DT5": "Wessex Water",
  "DT6": "Wessex Water",
  "DT8": "Wessex Water",
  "DT9": "Wessex Water",
  "S43": "Wessex Water",
  "SN10": "Wessex Water",
  "SN11": "Wessex Water",
  "SN12": "Wessex Water",
  "SN13": "Wessex Water",
  "SN14": "Wessex Water",
  "SN15": "Wessex Water",
  "SN9": "Wessex Water",
  "SO97": "Wessex Water",
  "SP1": "Wessex Water",
  "SP2": "Wessex Water",
  "SP4": "Wessex Water",
  "SP5": "Wessex Water",
  "SP7": "Wessex Water",
  "SP8": "Wessex Water",
  "TA1": "Wessex Water",
  "TA10": "Wessex Water",
  "TA11": "Wessex Water",
  "TA12": "Wessex Water",
  "TA13": "Wessex Water",
  "TA14": "Wessex Water",
  "TA15": "Wessex Water",
  "TA16": "Wessex Water",
  "TA17": "Wessex Water",
  "TA18": "Wessex Water",
  "TA19": "Wessex Water",
  "TA2": "Wessex Water",
  "TA20": "Wessex Water",
  "TA21": "Wessex Water",
  "TA23": "Wessex Water",
  "TA24": "Wessex Water",
  "TA3": "Wessex Water",
  "TA4": "Wessex Water",
  "TA5": "Wessex Water",
  "TA6": "Wessex Water",
  "TA7": "Wessex Water",
  "TA9": "Wessex Water",
  "DL10": "Yorkshire Water",
  "DL11": "Yorkshire Water",
  "DN10": "Yorkshire Water",
  "DN11": "Yorkshire Water",
  "DN12": "Yorkshire Water",
  "DN14": "Yorkshire Water",
  "DN2": "Yorkshire Water",
  "DN5": "Yorkshire Water",
  "DN6": "Yorkshire Water",
  "DN7": "Yorkshire Water",
  "DN8": "Yorkshire Water",
  "DN9": "Yorkshire Water",
  "HD1": "Yorkshire Water",
  "HD2": "Yorkshire Water",
  "HD3": "Yorkshire Water",
  "HD4": "Yorkshire Water",
  "HD5": "Yorkshire Water",
  "HD6": "Yorkshire Water",
  "HD7": "Yorkshire Water",
  "HD8": "Yorkshire Water",
  "HD9": "Yorkshire Water",
  "HG1": "Yorkshire Water",
  "HG2": "Yorkshire Water",
  "HG3": "Yorkshire Water",
  "HG4": "Yorkshire Water",
  "HG5": "Yorkshire Water",
  "HU1": "Yorkshire Water",
  "HU10": "Yorkshire Water",
  "HU11": "Yorkshire Water",
  "HU12": "Yorkshire Water",
  "HU13": "Yorkshire Water",
  "HU14": "Yorkshire Water",
  "HU15": "Yorkshire Water",
  "HU16": "Yorkshire Water",
  "HU17": "Yorkshire Water",
  "HU18": "Yorkshire Water",
  "HU19": "Yorkshire Water",
  "HU2": "Yorkshire Water",
  "HU20": "Yorkshire Water",
  "HU3": "Yorkshire Water",
  "HU4": "Yorkshire Water",
  "HU5": "Yorkshire Water",
  "HU6": "Yorkshire Water",
  "HU7": "Yorkshire Water",
  "HU8": "Yorkshire Water",
  "HU9": "Yorkshire Water",
  "HX1": "Yorkshire Water",
  "HX2": "Yorkshire Water",
  "HX3": "Yorkshire Water",
  "HX4": "Yorkshire Water",
  "HX5": "Yorkshire Water",
  "HX6": "Yorkshire Water",
  "HX7": "Yorkshire Water",
  "OL14": "Yorkshire Water",
  "S1": "Yorkshire Water",
  "S10": "Yorkshire Water",
  "S11": "Yorkshire Water",
  "S12": "Yorkshire Water",
  "S13": "Yorkshire Water",
  "S14": "Yorkshire Water",
  "S17": "Yorkshire Water",
  "S2": "Yorkshire Water",
  "S20": "Yorkshire Water",
  "S26": "Yorkshire Water",
  "S3": "Yorkshire Water",
  "S35": "Yorkshire Water",
  "S36": "Yorkshire Water",
  "S4": "Yorkshire Water",
  "S5": "Yorkshire Water",
  "S6": "Yorkshire Water",
  "S60": "Yorkshire Water",
  "S61": "Yorkshire Water",
  "S62": "Yorkshire Water",
  "WS10": "Severn Trent",
  "S63": "Yorkshire Water",
  "S64": "Yorkshire Water",
  "WS13": "South Staffordshire Water",
  "S65": "Yorkshire Water",
  "S7": "Yorkshire Water",
  "S70": "Yorkshire Water",
  "S71": "Yorkshire Water",
  "S72": "Yorkshire Water",
  "S73": "Yorkshire Water",
  "S74": "Yorkshire Water",
  "WS7": "Severn Trent",
  "S75": "Yorkshire Water",
  "S8": "Yorkshire Water",
  "S9": "Yorkshire Water",
  "WF1": "Yorkshire Water",
  "WF10": "Yorkshire Water",
  "WF11": "Yorkshire Water",
  "WF12": "Yorkshire Water",
  "WF13": "Yorkshire Water",
  "WF14": "Yorkshire Water",
  "WF15": "Yorkshire Water",
  "WF16": "Yorkshire Water",
  "WF17": "Yorkshire Water",
  "WF2": "Yorkshire Water",
  "WF3": "Yorkshire Water",
  "WF4": "Yorkshire Water",
  "WF5": "Yorkshire Water",
  "WF6": "Yorkshire Water",
  "WF7": "Yorkshire Water",
  "WF8": "Yorkshire Water",
  "WF9": "Yorkshire Water",
  "WF90": "Yorkshire Water",
  "YO1": "Yorkshire Water",
  "YO10": "Yorkshire Water",
  "YO11": "Yorkshire Water",
  "YO12": "Yorkshire Water",
  "YO13": "Yorkshire Water",
  "YO14": "Yorkshire Water",
  "YO15": "Yorkshire Water",
  "YO16": "Yorkshire Water",
  "YO17": "Yorkshire Water",
  "YO18": "Yorkshire Water",
  "YO19": "Yorkshire Water",
  "YO21": "Yorkshire Water",
  "YO22": "Yorkshire Water",
  "YO23": "Yorkshire Water",
  "YO24": "Yorkshire Water",
  "YO25": "Yorkshire Water",
  "YO26": "Yorkshire Water",
  "YO30": "Yorkshire Water",
  "YO31": "Yorkshire Water",
  "YO32": "Yorkshire Water",
  "YO41": "Yorkshire Water",
  "YO42": "Yorkshire Water",
  "YO43": "Yorkshire Water",
  "YO51": "Yorkshire Water",
  "YO60": "Yorkshire Water",
  "YO61": "Yorkshire Water",
  "YO62": "Yorkshire Water",
  "YO7": "Yorkshire Water",
  "YO8": "Yorkshire Water",
  "YO90": "Yorkshire Water"
};
